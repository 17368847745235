import { action, computed, observable } from "mobx";
import type RootStore from "./RootStore";
import Mission, { MissionOptions } from "./Mission";
import { TaskCampaign, TaskOption } from "@/services/types";

export default class MissionStore {
  rootStore: RootStore;
  @observable private accessor missions: Map<number, Mission> = new Map();
  @observable private accessor missionCampaign: Map<number, TaskCampaign> = new Map();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  hardcodeOption(option: TaskOption): MissionOptions | false {
    const id = option.externalId;
    const result = { ...option, id };
    return result;
  }

  @action init({ task: taskOptions, taskCampaign }: { task: TaskOption[], taskCampaign: TaskCampaign[] }) {
    this.missions = new Map();

    taskCampaign.forEach((campaign) => {
      if (!campaign.isActive) return;

      this.missionCampaign.set(campaign.externalId, campaign);
    });

    taskOptions.forEach((option) => {
      const missionOption = this.hardcodeOption(option);
      if (missionOption) {
        const mission = new Mission(missionOption);
        this.missions.set(missionOption.id, mission);
        //setTimeout(()=>mission.check(), 2000);
      }
    });
  }

  @action initState(state: number[]) {
    state.forEach((id) => {
      const mission = this.getById(id);
      if (mission) {
        mission.initState(true);
      } else {
        console.warn("Unknown mission id in user data");
      }
    });
  }

  @computed public get all() {
    return [...this.missions.values()]
      .filter((m) => m.show)
      .sort((a, b) => a.id - b.id);
  }

  @computed public get campaignMissions () {
    return [...this.missionCampaign.values()].map(campaign => {
      let isDone = true;
      let reward = 0;

      this.all.forEach(mission => {
        if (mission.campaignId !== campaign.externalId) return;

        reward += !mission.isDone ? mission.reward : 0; 

        if (!mission.isDone && isDone) {
          isDone = mission.isDone;
        }
      });

      const mission = new Mission({
        id: campaign.externalId,
        externalId: campaign.externalId,
        rewardCoins: reward,
        isActive: campaign.isActive,
        nameEn: campaign.nameEn,
        descriptionEn: campaign.descriptionEn,
        campaignExternalId: null,
        action: "",
        type: "",
        icon: campaign.icon,
        link: "",
        pin: false,
      })

      mission.initState(isDone);

      return mission;
    })
  }

  getById(id: number) {
    return this.missions.get(id);
  }

  getCampaignById(id: number) {
    return this.missionCampaign.get(id);
  }

  getByType(type: string) {
    const pinned: Mission[] = [];
    const rest: Mission[] = [];
    const done: Mission[] = [];

    this.all.forEach(mission => {
        if(mission.type !== type) return;

        if (mission.pin && !mission.isDone) return pinned.push(mission);
        if (mission.isDone) return done.push(mission);

        rest.push(mission);
    });

    return [...pinned.sort((a,b) => a.id - b.id), ...rest, ...done];
  }
}
