import clsx from "clsx";
import { FC, PropsWithChildren } from "react";

type IconProps = {
  content: string | JSX.Element;
  size?: string;
  rotate?: string;
  className?: string;
}

export const Icon: FC<PropsWithChildren<IconProps>> = ({
  content,
  children,
  size,
  rotate,
  className = '',
}) => {
  return (
    <i className={clsx(className)} style={{
      fontSize: size, transform: `rotate(${rotate}deg)`,
    }}>
      {content || children}
    </i>
  )
}
