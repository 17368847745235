import { useEffect, useState } from "react";
import styles from "./styles.module.sass";
import ReactModal from "react-modal";
import { Button } from "@/components/Button";
import Coin from "@/assets/capybara-images/coin.svg?react";
import ModalCloser from "@/assets/capybara-images/modalCloser.svg?react";
import { leagueStore } from "@/store";
import { observer } from "mobx-react-lite";
import { findLeagueConfig } from "@/utils/leagueConfig";

export const LeagueLevelUpModal = observer(() => {
  const [closing, setClosing] = useState(false);
  const [customStyles, setStyles] = useState({
    backgroundColor: "",
    backgroundImage: "",
  });
  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      leagueStore.closeDialog();
      setClosing(false);
    }, 300);
  };

  useEffect(() => {
    const result = findLeagueConfig(leagueStore.current.leagueNameEn);

    setStyles({
      backgroundColor: result?.backgroundColor ?? "",
      backgroundImage: result?.img ?? "",
    });
  }, []);

  return (
    <ReactModal
      ariaHideApp={false}
      overlayClassName={styles.modalOverlay}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleClose}
      className={`${styles.modalContainer} ${closing ? styles.modalClose : ""}`}
      isOpen={leagueStore.levelUpDialog}
    >
      <div className={styles.modalContentWrapper}>
        <div
          className={styles.modalContent}
          style={{ background: customStyles.backgroundColor }}
        >
          <ModalCloser
            className={styles.modalCloser}
            onClick={handleClose}
          ></ModalCloser>
          <img
            style={{
              width: "250px",
              marginBottom: "-140px",
            }}
            src={customStyles.backgroundImage}
            alt=""
          />
          <div
            style={{
              width: "100%",
              height: "50%",
              position: "absolute",
              bottom: "1px",
              margin: "15px",
              filter: "blur(26px)",
              transform: "translate3d(0px, 0px, 0px)",
              background: "#00000066",
              borderBottomRightRadius: "34px",
              borderBottomLeftRadius: "34px",
            }}
          />
          <div className={styles.boostBlockCostContainer}>
            <div className={styles.costAmount}>
              <p>
                {`+${leagueStore.current.lvlupBonus.toLocaleString("en-US")}`}{" "}
              </p>
              <Coin width={16} height={16} />
            </div>
            <div className={styles.level}>
              <p>
                {`Congratulations! You’ve been promoted to the ${leagueStore.current.leagueNameEn} League`}
              </p>
            </div>
          </div>
          <Button
            onClick={handleClose}
            label="Got it!"
            styleType="primary"
            block={true}
          ></Button>
        </div>
      </div>
    </ReactModal>
  );
});
