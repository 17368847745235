import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";

interface LeagueUserProps {
  img: string;
  userName: string;
  position: number;
  index: number;
  coins: number;
  isUser: boolean;
}

const getBackgroundColor = (index: number): string | undefined => {
  switch (index) {
    case 0:
      return "rgba(237, 172, 12, 1)";
    case 1:
      return "rgba(134, 134, 134, 1)";
    case 2:
      return "rgba(188, 102, 41, 1)";
    default:
      break;
  }
};

export const LeagueUser = ({
  img,
  userName,
  position,
  index,
  coins,
  isUser,
}: LeagueUserProps) => {
  return (
    <div className={styles.leagueUserContainer}>
      <div className={styles.leagueUser}>
        <div
          className={styles.record}
          style={{
            backgroundColor: isUser ? "#164F8D": getBackgroundColor(index) ?? undefined,
            borderRadius: index > 300 ? "35px" : undefined,
            padding: index > 300 ? "3.5px 8px" : undefined,
          }}
        >
          {(position).toLocaleString()}
        </div>
        <img src={img} alt={"user"} />
        <div>
          <p className={styles.userName}>{userName}</p>
          { isUser && <p className={styles.you}>You</p> }
        </div>
      </div>
      <div className={styles.coinsAmount}>
        <p>{`${coins.toLocaleString("en-US")}`} </p>
        <Coin width={16} height={16} />
      </div>
    </div>
  );
};
