import { observable, runInAction } from "mobx";

interface Tap {
  active: boolean;
  run: boolean;
  amount: number;
  x: number;
  y: number;
}

const taps: Tap[] = [];
for (let i = 20; i--; ) {
  taps.push({ active: false, amount: 0, run: false, x: 0, y: 0 });
}

const state = observable({
  taps: taps,
  addTap(x: number, y: number, amount: number) {
    const unused = this.taps.find((tap) => !tap.active);
    if (unused) {
      unused.active = true;
      unused.amount = amount;
      unused.run = false;
      unused.x = x;
      unused.y = y;
      setTimeout(() => runInAction(() => (unused.run = true)), 0);
      setTimeout(() => runInAction(() => (unused.active = false)), 500);
    } else {
      console.warn("Not enough tap divs");
    }
  },
});

export type { Tap };
export default state;
