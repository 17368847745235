import { action, observable } from "mobx";
import img from "@/assets/capybara-images/capynft.png"

export const collectionsState = observable({
  collectTip: false,
  mintTip: false,
  feeTip: false,
  
  //TODO: move to collections global state
  receivedModal: false,
  receivedData: { name: 'Capybara', img },
});

export const setCollectTip = action((state: boolean) => {
  collectionsState.collectTip = state;
});

export const setMintTip = action((state: boolean) => {
  collectionsState.mintTip = state;
});

export const setFeeTip = action((state: boolean) => {
  collectionsState.feeTip = state;
});

export const setReceivedModal = action((state: boolean) => {
  collectionsState.receivedModal = state;
});