import type RootStore from "./RootStore";
import { observable, computed, action, flow } from "mobx";
import { delay, isBackend, isProduction } from "@/utils";
import { backend } from "@/services";
import { GameOptions, SyncResponse } from "@/services/types";
import {
  autoFeedBotStore,
  boostEnergyInstantStore,
  boostMaxSizeStore,
  boostPortionStore,
  boostSpeedStore,
  boostTapStore,
  friendStore,
  leagueStore,
  missionStore,
} from ".";

export interface GameState {
  balance: number;
  totalBalance: number;
  energy: number;
  dailyEnergy: number;
}

export default class GameStore {
  rootStore: RootStore;
  lastTime: number = 0;
  @observable public accessor sessionTime: number = 0;
  @observable public accessor devOverlay: boolean = false;
  @observable public accessor state: GameState;
  @observable public accessor serverInitTimestamp: number = 0;

  @observable public accessor nextMidnightTimestamp: number = 0;
  @observable public accessor midnight: number = 0;

  @observable public accessor betaTestDialog: boolean = isBackend;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.state = {
      balance: 0,
      energy: 1000,
      totalBalance: 0,
      dailyEnergy: 0,
    };
  }

  @computed public get now() {
    if (!this.serverInitTimestamp) {
      console.warn("gameStore.now without server time");
    }
    return this.serverInitTimestamp + this.sessionTime;
  }

  @computed public get tapIncome() {
    let result = 0;
    result += boostPortionStore.effect;
    return result;
  }

  @computed public get energyIncome() {
    let result = 0;
    result += boostSpeedStore.effect;
    return result;
  }

  @computed public get maxEnergy() {
    let result = 0;
    result += boostMaxSizeStore.effect;
    return result;
  }

  @action.bound public refillFullEnergy() {
    this.state.energy = this.maxEnergy;
  }

  @computed public get balance() {
    return this.state.balance;
  }

  @computed public get totalBalance() {
    return this.state.totalBalance;
  }

  @computed public get energy() {
    return this.state.energy;
  }

  @computed public get dailyEnergy() {
    return this.state.dailyEnergy;
  }

  @computed public get dailyMaxEnergy() {
    return 0;
  }

  @computed public get isDailyEnergyLimit() {
    return false;
    //return this.dailyEnergy >= this.dailyMaxEnergy;
  }

  @action.bound tap(): number {
    let income = this.tapIncome;
    if (boostTapStore.isActive) income *= 3;
    if (this.state.energy >= income) {
      this.state.balance += income;
      this.state.totalBalance += income;
      if (!boostTapStore.isActive) this.state.energy -= income;
      backend.tap();
      return income;
    }
    return 0;
  }

  @action update(delta: number) {
    if (boostTapStore.isActive && boostTapStore.activeEta <= delta) {
      backend.sendTaps();
    }
    this.sessionTime += delta;
    if (this.energy < this.maxEnergy && !this.isDailyEnergyLimit) {
      const energyIncome = (this.energyIncome * delta) / 1000;
      this.state.energy += energyIncome;
      this.state.dailyEnergy += energyIncome;
      if (this.energy > this.maxEnergy) {
        this.state.energy = this.maxEnergy;
      }
    }
    if (this.now > this.nextMidnightTimestamp) {
      this.state.dailyEnergy = 50;
      this.midnight++;
      this.nextMidnightTimestamp += 24 * 60 * 60 * 1000;
    }
  }

  @action.bound addBalance(add: number, total = true) {
    if (total && add > 0) {
      this.state.totalBalance += add;
    }
    this.state.balance += add;
    if (!isBackend) backend.sendTaps();
  }

  @action protected initOptions(options: GameOptions) {
    options;
    boostTapStore.init(options.boost.find((b) => b.externalId === 5));
    boostEnergyInstantStore.init(options.boost.find((b) => b.externalId === 4));
    boostMaxSizeStore.init(options.boost.find((b) => b.externalId === 1));
    boostSpeedStore.init(options.boost.find((b) => b.externalId === 2));
    boostPortionStore.init(options.boost.find((b) => b.externalId === 3));
    autoFeedBotStore.init(options.boost.find((b) => b.externalId === 6));

    missionStore.init({
      task: options.task,
      taskCampaign: options.taskCampaign,
    });
    leagueStore.init(options.league);
    friendStore.init();
    this.rootStore.initFlags.initOptions = true;
  }

  @action initState(state: SyncResponse) {
    this.serverInitTimestamp = Math.trunc(state.lastSyncTimestamp * 1000) + 1;
    this.sessionTime = 0;
    this.nextMidnightTimestamp =
      new Date(this.serverInitTimestamp).setUTCHours(24, 0, 0, 0) + 1000;
    this.state.balance = state.balance;
    this.state.totalBalance = state.totalBalance;
    this.state.energy = state.energy;
    this.state.dailyEnergy = 50; //state.dailyEnergyRestored + 50;

    boostTapStore.initState(state.turboBoost);
    boostEnergyInstantStore.initState(state.instantRecoveryBoost);
    boostMaxSizeStore.initState(state.maxFoodStockSizeBoost);
    boostSpeedStore.initState(state.restockSpeedBoost);
    boostPortionStore.initState(state.portionSizeBoost);
    autoFeedBotStore.initState({
      isOn: isProduction ? false : state.autoFeedBotIsOn,
      income: state.autoFeedBotIncome,
    });

    missionStore.initState(state.missions);
    leagueStore.initState(state.leagueLevel);
    friendStore.initState();

    this.rootStore.initFlags.initState = true;
  }

  @flow.bound *init(options: GameOptions, state: SyncResponse) {
    yield backend.ready;
    this.initOptions(options);

    this.initState(state);

    if (!isProduction && location.search == "?bot") {
      yield import("@/bot");
    }

    this.loop();
  }

  @flow.bound *loop() {
    while (true) {
      const now = Date.now();
      if (this.lastTime) {
        const delta = now - this.lastTime;
        this.update(delta);
      }
      this.lastTime = now;
      yield delay(1000);
    }
  }

  @action.bound confirmBetaTest() {
    this.betaTestDialog = false;
  }
}
