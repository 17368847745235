import { FC, PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";

import styles from '../styles.module.sass';

type CollectionProps = {
  title: string,
}

export const Collection: FC<PropsWithChildren<CollectionProps>> = observer((
  {
    title,
    children,
  }
) => {
  return (
    <div className={styles.collection}>
      <h3 className={styles.collection__title}>{ title }</h3>
      <div className={styles.collection__items}>
        {
          children
        }
      </div>
    </div>
  )
});
