import classes from "./ErrorHint.module.sass";
import { isProduction } from "@/utils";
import rootStore from "@/store";
import ButtonWithAction from "../ButtonWithAction";

function ErrorHint() {
  function reloadPage() {
    window.location.reload();
  }

  const doneSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      style={{ marginBottom: "-4px", marginRight: "2px" }}
    >
      <path
        d="M9 19.4L3.3 13.7 4.7 12.3 9 16.6 20.3 5.3 21.7 6.7z"
        fill="green"
      ></path>
    </svg>
  );

  const errorSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      style={{ marginBottom: "-4px", marginRight: "2px" }}
    >
      <path
        d="M11 0.7H13V23.3H11z"
        transform="rotate(-45.001 12 12)"
        fill="red"
      ></path>
      <path
        d="M0.7 11H23.3V13H0.7z"
        transform="rotate(-45.001 12 12)"
        fill="red"
      ></path>
    </svg>
  );

  if (isProduction && rootStore.errorInInit && rootStore.initFlagsError) {
    console.error(
      Object.entries(rootStore.initFlagsError).map(
        ([key, value]) => key.toString() + "=" + value.toString()
      )
    );
  }

  return (
    <div className={classes.errorHint}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          Oops... Something went wrong. Try restarting the mini-app.
        </div>
        <ButtonWithAction action={reloadPage}>Reload</ButtonWithAction>

        {!isProduction && (
          <>
            <div className={classes.errorSpoiler}>
              Ошибка: {rootStore.errorMessage}
            </div>
            {rootStore.errorInInit && (
              <details className={classes.errorSpoiler}>
                <summary>Подробности инициализации:</summary>
                {rootStore.initFlagsError &&
                  Object.entries(rootStore.initFlagsError).map(
                    ([key, value]) => (
                      <div className={classes.text} key={key}>
                        {value ? doneSvg : errorSvg}
                        {String(key)}
                      </div>
                    )
                  )}
              </details>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ErrorHint;
