import React from "react";
import clsx from "clsx";
import classes from "./ButtonWithAction.module.sass";
import useHapticFeedback from "@/hooks/useHapticFeedback";

type Type = "primary" | "action" | "upgrade" | "danger" | "secondary";
type Size = "normal" | "small";

interface ButtonProps {
  children: React.ReactNode;
  type?: Type;
  size?: Size;
  disabled?: boolean;
  loading?: boolean;
  eventsNone?: boolean;
  action?: () => void;
  isHaptic?: boolean;
  isDoubleHaptic?: boolean;
}

function ButtonWithAction(props: ButtonProps) {
  const {
    children,
    type = "primary",
    size = "normal",
    disabled,
    loading,
    eventsNone,
    action,
    isHaptic,
    isDoubleHaptic,
  } = props;

  const triggerHapticFeedback = useHapticFeedback(isDoubleHaptic);

  const handleClick = () => {
    if (isHaptic || isDoubleHaptic) {
      triggerHapticFeedback();
    }
    if (action) {
      action();
    }
  };

  const className = clsx(
    classes.button,
    classes[type],
    classes[size],
    loading && classes.loading,
    eventsNone && classes.eventsNone
  );

  return (
    <button className={className} disabled={disabled} onClick={handleClick}>
      {!loading && children}
      {loading && <div className={classes.loader} />}
    </button>
  );
}

export default ButtonWithAction;
