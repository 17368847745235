import AutoFeedBotStore from "./AutoFeedBotStore";
import BoostEnergyInstantStore from "./BoostEnergyInstantStore";
import BoostMaxSizeStore from "./BoostMaxSizeStore";
import BoostPortionStore from "./BoostPortionStore";
import BoostSpeedStore from "./BoostSpeedStore";
import BoostTapStore from "./BoostTapStore";
import FriendStore from "./FriendStore";
import LeagueStore from "./LeagueStore";
import MissionStore from "./MissionStore";
import LootboxesStore from "./LootboxesStore";
import LeaguesLeaderboardStore from "./LeaguesLeaderboardStore";
import RootStore from "./RootStore";

export const rootStore = new RootStore();
export const gameStore = rootStore.gameStore;
export const walletStore = rootStore.walletStore;
export const toastManagerStore = rootStore.toastManagerStore;
export const storyManagerStore = rootStore.storyManagerStore;

export const boostTapStore = new BoostTapStore(rootStore);
export const boostEnergyInstantStore = new BoostEnergyInstantStore(rootStore);
export const boostMaxSizeStore = new BoostMaxSizeStore(rootStore);
export const boostSpeedStore = new BoostSpeedStore(rootStore);
export const boostPortionStore = new BoostPortionStore(rootStore);
export const autoFeedBotStore = new AutoFeedBotStore(rootStore);
export const lootboxesStore = new LootboxesStore();

export const missionStore = new MissionStore(rootStore);
export const leagueStore = new LeagueStore(rootStore);
export const friendStore = new FriendStore(rootStore);
export const leaguesLeaderboardStore = new LeaguesLeaderboardStore(rootStore);

export default rootStore;
