import { action, computed, observable, flow } from "mobx";
import type RootStore from "./RootStore";
import { FriendResponse, FriendResponseFriend } from "@/services/types";
import { backend } from "@/services";

export interface Friend extends FriendResponseFriend {}

type FriendPagination = {
  offset: number;
  limit: number;
  from: number;
  to: number;
  current: number;
}

export default class FriendStore {
  rootStore: RootStore;
  @observable.ref private accessor _friends: Friend[] = [];
  @observable public accessor loading: boolean = false;
  @observable public accessor loadingMore: boolean = false;
  @observable public accessor loaded: boolean = false;
  @observable public accessor totalCount: number = 0;
  @observable public accessor hasMore: boolean = false;
  @observable.ref public accessor pagination: FriendPagination = {
    offset: 0,
    limit: 20,
    from: 1,
    to: 1,
    current: 1,
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action init() {
    this._friends = [];
  }

  @action initState() {
    this._friends = [];
    this.loaded = false;
  }

  @computed public get friends() {
    // const clone = structuredClone(this._friends);
    // const sorted = clone.sort((a, b) => a.leagueLevel! - b.leagueLevel!);
    return this._friends;
  }

  @flow.bound *refresh() {
    this.loading = true;

    const { friends, hasMore, totalCount }: FriendResponse = yield backend
      .sendFriendsV2({ limit: this.pagination.limit, offset: this.pagination.offset });
    
    this._friends = friends;
      
    this.hasMore = hasMore;
    this.totalCount = totalCount;
    this.pagination.current = 1;
    this.pagination.to = Math.ceil(totalCount / this.pagination.limit);
    this.incOffset();

    this.loading = false;
    this.loaded = true;
  }

  @flow.bound *loadMore() {
    this.loadingMore = true;

    const { friends, hasMore, totalCount }: FriendResponse = yield backend
      .sendFriendsV2({ limit: this.pagination.limit, offset: this.pagination.offset });

    this._friends = [...this._friends, ...friends];
    this.hasMore = hasMore;
    this.totalCount = Number(totalCount);

    this.incOffset();

    this.loadingMore = false;
  }

  @action.bound incOffset() {
    this.pagination.offset += this.pagination.limit;
  }

  @action.bound nextPage() {
    if (this.pagination.current >= this.pagination.to) return;

    this.pagination.current += 1;
  }

  @action.bound prevPage() {
    if (this.pagination.current <= this.pagination.from) return;

    this.pagination.current -= 1;
  }
}
