import { observer } from "mobx-react-lite";
import classes from "./CoinTap.module.sass";
import type { Tap } from "../tapState";

const fruits = ["🍓", "🍌", "🍎", "🍑", "🍍", "🍒", "🍇", "🍉", "🥭", "🍈"];

const CoinTap = observer(({ tap }: { tap: Tap }) => {
  let className = classes.coinTap;
  if (tap.active) className += " " + classes.active;
  const styles = {
    transform: `translate(${tap.x}px, ${tap.y}px)`,
    opacity: 1,
  };
  if (tap.run) {
    styles.transform = `translate(${tap.x}px, ${tap.y - 100}px)`;
    styles.opacity = 0;
  }

  return (
    <div className={className} style={styles}>
      +{`${tap.amount}${fruits[Math.floor(Math.random() * 10)] || "limit"}`}
    </div>
  );
});

export default CoinTap;
