import React from "react";
import styles from "./Loader.module.sass";
import animationData from "./Animation.json";
import Lottie from "lottie-react";

const Preloader: React.FC = () => {
  return (
    <div className={styles.preloader}>
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ width: 150, height: 500 }}
      />
    </div>
  );
};

export default Preloader;
