import { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import rocketImg from "@/assets/layout/rocket/rocket.png";
import { boostTapStore } from "@/store";
import { observer } from "mobx-react-lite";

const Rocket = observer(() => {
  const positionRef = useRef({ x: 0, y: 0 });
  const directionRef = useRef({ x: 1, y: 1 });
  const speed = 1;
  const rocketSize = 100;
  const frameId = useRef<number | null>(null);
  const rocketRef = useRef<HTMLDivElement>(null);

  const moveRocket = () => {
    const { innerWidth, innerHeight } = window;
    let { x, y } = positionRef.current;
    let { x: dx, y: dy } = directionRef.current;

    x += dx * speed;
    y += dy * speed;

    if (x <= 0 || x >= innerWidth - rocketSize) {
      dx = -dx;
    }
    if (y <= 0 || y >= innerHeight - rocketSize) {
      dy = -dy;
    }

    positionRef.current = { x, y };
    directionRef.current = { x: dx, y: dy };

    if (rocketRef.current) {
      rocketRef.current.style.transform = `translate(${x}px, ${y}px)`;
    }

    frameId.current = requestAnimationFrame(moveRocket);
  };

  useEffect(() => {
    frameId.current = requestAnimationFrame(moveRocket);

    return () => {
      if (frameId.current) {
        cancelAnimationFrame(frameId.current);
      }
    };
  }, []);

  return (
    <motion.div
      onClick={boostTapStore.use}
      ref={rocketRef}
      style={{
        width: rocketSize,
        height: rocketSize,
        backgroundImage: `url(${rocketImg})`,
        backgroundSize: "contain",
        position: "absolute",
        willChange: "transform",
        zIndex: 100,
      }}
    />
  );
});

export default Rocket;
