import { useEffect, useState } from "react";
import clsx from "clsx";
import { createPortal } from "react-dom";
import classes from "./Toast.module.sass";
import CloseIcon from "@/assets/layout/toast/close-back.svg?react";

interface ToastProps {
  id: number;
  message: string;
  icon?: string;
  isBlack?: boolean;
  duration?: number;
  onClose: (id: number) => void;
}

const Toast = ({
  id,
  message,
  icon,
  duration = 3000,
  onClose,
  isBlack,
}: ToastProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    const showTimer = setTimeout(() => {
      setIsVisible(true);
    }, 10);

    const fadeOutTimer = setTimeout(() => {
      setIsFadingOut(true);
    }, duration - 300);

    const unmountTimer = setTimeout(() => {
      setIsMounted(false);
      onClose(id);
    }, duration);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(unmountTimer);
    };
  }, [duration, onClose, id]);

  const handleClose = () => {
    setIsFadingOut(true);
    setTimeout(() => onClose(id), 300); // время совпадает с временем анимации
  };

  const className = clsx(classes.toast, {
    [classes.visible]: isVisible && !isFadingOut,
    [classes.fadingOut]: isFadingOut,
    [classes.mounted]: isMounted,
  });

  return isMounted
    ? createPortal(
        <div className={className}>
          {icon && (
            <div className={classes.icon}>
              <img src={icon} alt="icon" />
            </div>
          )}
          <div
            style={{
              color: isBlack ? "black" : "white",
            }}
            className={classes.message}
          >
            {message}
          </div>
          <button className={classes.closeBtn} onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>,
        document.getElementById("toasts")!
      )
    : null;
};

export default Toast;
