import { FC } from "react";
import clsx from "clsx";

import styles from './Badge.module.sass';
import { observer } from "mobx-react-lite";

type BadgeProps = {
  children: React.ReactNode;
  className?: string;
  color?: 'lightblue' | 'blue' | 'darkblue' | 'transparent';
  size?: 's' | 'm' | 'xl';
  width?: string;
  visible?: boolean;
  onClick?: VoidFunction;
};

export const Badge: FC<BadgeProps> = observer(({
  children,
  className,
  color = 'blue',
  size = 's',
  visible = true,
  width = '',
  onClick
}) => {
  return (
    <>
      {
        visible && (
          <button style={{ ...({width}) }} onClick={onClick} className={clsx(styles.badge, {
            [`${styles[`badge_${color}`]}`]: true,
            [`${styles[`badge_${size}`]}`]: true,
            ...(className ? { [className]: true } : {}),
          })}>{ children }</button>
        )
      }
    </>
  );
});
