import { observer } from "mobx-react-lite";

import logo from "@/assets/capybara-images/logo.png";
import nourished from "@/assets/capybara-images/nourished.png";
import full from "@/assets/capybara-images/full.png";
import hungry from "@/assets/capybara-images/hungry.png";
import QR from "@/assets/layout/play-on-mobile/qr.svg?react";

import classes from "./LockScreen.module.sass";
import clsx from "clsx";

const LockScreen = observer(() => {
  return (
    <div className={classes.lockScreen}>
      <img src={logo} alt="logo" className={classes.logo}/>
      <h2 className={classes.title}>
        {`Forget the\ndesktop. Mobile\ngaming rules!`}
      </h2>
      <div className={classes.content}>
        <QR className={classes.qr} />
        <img src={nourished} alt="nourished" className={clsx(classes.capybara, classes.capybara_nourished)}/>
        <img src={full} alt="full" className={clsx(classes.capybara, classes.capybara_full)}/>
        <img src={hungry} alt="hungry" className={clsx(classes.capybara, classes.capybara_hungry)}/>
      </div>
    </div>
  );
});

export default LockScreen;
