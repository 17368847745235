import { action, computed, observable } from "mobx";
import type RootStore from "./RootStore";
import { BoostOption } from "@/services/types";
import { gameStore, toastManagerStore } from "@/store";
import { backend } from "@/services";

interface State {
  isOn: boolean;
  income: number;
  dialogViewed: boolean;
}

interface Options {
  id: number;
  name: string;
  description: string;
  initialCost: number;
}

export interface SuccessToast {
  text: string;
  icon: string;
}

export default class AutoFeedBotStore {
  rootStore: RootStore;
  @observable private accessor state: State = { isOn: false, income: 0, dialogViewed: false };
  @observable.ref private accessor options: Options | undefined;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action init(bo: BoostOption | undefined) {
    if (!bo) {
      throw new Error("Empty options for passive boost");
    }
    if (bo.nameEn && bo.descriptionEn) {
      this.options = {
        id: bo.externalId,
        name: bo.nameEn,
        description: bo.descriptionEn,
        initialCost: bo.initialCost || 25000,
      };
    } else {
      throw new Error("incorrect passive boost options");
    }
  }

  @action initState({isOn, income}: { isOn: boolean; income: number }) {
    this.state.isOn = isOn;
    this.state.income = income > 0 ? income : 0;
  }

  @computed public get id() {
    return this.options!.id;
  }

  @computed public get cost() {
    return (
      this.options!.initialCost
    );
  }

  @computed public get name() {
    return this.options!.name;
  }

  @computed public get desc() {
    return this.options!.description;
  }

  @computed public get isOn() {
    return this.state.isOn;
  }

  @computed public get income() {
    return this.state.income;
  }

  @computed public get viewed() {
    return this.state.dialogViewed;
  }

  @computed public get canBuy() {
    return gameStore.balance >= this.cost && !this.state.isOn;
  }

  @action.bound setDialogViewed(viewed: boolean) {
    this.state.dialogViewed = viewed;
  }

  @action.bound buy(successToast?: SuccessToast) {
    if (this.canBuy && this.cost) {
      backend.sendBoost(this.id, 0);
      this.state.isOn = true;
      gameStore.addBalance(-this.cost);
      successToast &&
        toastManagerStore.addToast(successToast.text, successToast.icon);
    }
  }
}
