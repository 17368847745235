import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import Coin from "@/assets/icons/coin.svg?react";
import coinImg from "@/assets/icons/coin.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { TaskItem } from "./TaskItem";
import { missionStore, storyManagerStore, walletStore } from "@/store";
import { observer } from "mobx-react-lite";
import { formatNumber } from "@/utils";
import { SuccessToast } from "@/store/Mission";
import { handleMint } from "@/utils/handleExpandPage";
import { Dialog } from "@/components/Dialog";
import { useState } from "react";
import SuiImg from "@/assets/capybara-images/sui-80.png";

export const Earn = observer(() => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleCheck = (id: number) => {
    const item = missionStore.getById(id);

    const toastData: SuccessToast = {
      text: `+${formatNumber(item?.reward ?? 0)}`,
      icon: coinImg,
    };

    if (item) {
      if (item?.action === "LINK" || item?.action === "CHANNEL") {
        console.warn("item?.action go", item?.action);
        item.go(true, toastData);
      } else {
        console.warn("Else condition", item?.action);
        item.isDone === false && item.check(true, toastData);
      }
    }
  };

  const redirectToExploreTask = (id: number, isActive: boolean) => () => {
    if (!isActive) return;
    navigate(`/explore/${id}`);
  };

  return (
    <>
      <div
        style={{
          overflow: "scroll",
        }}
      >
        <div className={styles.title}>
          <Coin width={64} height={64} />
          <p className={styles.coinsText}>Earn more Coins</p>
        </div>
        <div className={styles.dailyTasksWrapper}>
          <p className={styles.tasksTitle}>Daily Tasks</p>
          <div
            style={{
              height: 112,
              display: "flex",
              gap: 4,
              paddingRight: "16px",
            }}
          >
            {missionStore.getByType("DAILY").map((item) => {
              return (
                item && (
                  <TaskItem
                    key={item.name}
                    id={item.id}
                    onClick={() => {
                      if (item.id === 1002) {
                        if (!item.isDone && walletStore.nft) {
                          if (
                            walletStore.isCreated &&
                            walletStore.isNftCanCheckIn
                          ) {
                            handleMint({
                              callback: setIsOpen,
                              navigate,
                              isCheckIn: true,
                            });
                          } else {
                            handleCheck(item.id);
                          }
                          return;
                        }
                      } else {
                        handleCheck(item.id);
                      }
                    }}
                    taskIcon={item.icon}
                    type="block"
                    title={item.name}
                    reward={item.reward}
                    isCollected={item.isDone}
                  />
                )
              );
            })}
          </div>
        </div>
        {!!missionStore.campaignMissions.length && (
          <div className={styles.dailyTasksWrapper}>
            <p className={styles.tasksTitle}>Explore</p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: 4,
                paddingRight: "16px",
              }}
            >
              {missionStore.campaignMissions.map((item) => {
                return (
                  item && (
                    <TaskItem
                      key={item.id}
                      id={item.id}
                      onClick={redirectToExploreTask(item.id, item.isActive)}
                      taskIcon={item.icon}
                      type="block"
                      title={item.name}
                      reward={item.reward}
                      isCollected={item.isDone}
                    />
                  )
                );
              })}
            </div>
          </div>
        )}
        <div className={styles.tasksWrapper}>
          <Swiper slidesPerView={"auto"}>
            <SwiperSlide
              style={{
                width: "92%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <p className={styles.tasksTitle}>Onboarding Tasks</p>
              <div className={styles.tasksList}>
                {missionStore.getByType("ONBOARDING").map((item) => {
                  return (
                    item && (
                      <TaskItem
                        key={item.name}
                        id={item.id}
                        onClick={() => {
                          if (item.id === 9) {
                            storyManagerStore.showStorySet("onboarding");
                          } else if (item.id === 10) {
                            storyManagerStore.showStorySet("onboardingSui");
                          } else if (item.id === 11) {
                            storyManagerStore.showStorySet("onboardingZlog");
                          } else if (
                            item.id === 1003 &&
                            !item.isDone &&
                            !walletStore.nft
                          ) {
                            handleMint({
                              callback: setIsOpen,
                              navigate,
                            });
                          }

                          handleCheck(item.id);
                        }}
                        taskIcon={item.icon}
                        type="inline"
                        title={item.name}
                        reward={item.reward}
                        isCollected={item.isDone}
                      />
                    )
                  );
                })}
                <div
                  style={{
                    minHeight: "92px",
                  }}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <p className={styles.tasksTitle}>Social Tasks</p>
              <div className={styles.tasksList}>
                {missionStore.getByType("SOCIAL").map((item) => {
                  return (
                    item && (
                      <TaskItem
                        key={item.name}
                        id={item.id}
                        type="inline"
                        onClick={() => handleCheck(item.id)}
                        taskIcon={item.icon}
                        title={item.name}
                        reward={item.reward}
                        isCollected={item.isDone}
                      />
                    )
                  );
                })}
                <div
                  style={{
                    minHeight: "92px",
                  }}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Dialog
        modalWallpaper={""}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onAction={() => navigate("/wallet")}
        title={"Not enough SUI"}
        text={`You don’t have enough SUI in your wallet to cover the Transaction Fee.
           Please Buy SUI or deposit from external wallet.`}
        modalTitleColor={"#C0E6FF;"}
        modalIcon={<img width={80} height={80} src={SuiImg} />}
        buttonText={"Buy"}
        additionalbuttonText="Deposit"
        handleAdditionalAction={() => navigate("/wallet?receive=true")}
      />
    </>
  );
});
