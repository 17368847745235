import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC } from 'react';

const confettiColors = ['#e4ec61', '#37c3ed', '#adfd6c', '#f9b358', '#b6fdff', '#faaeff'];

type ConfettiProps = {
  visible?: boolean;
  colors?: string[];
  className?: string;
  count?: number;
  styles?: React.CSSProperties;
}

const ConfettiPiece = ({ color }: { color: string }) => {
  const width = Math.random() * 10 + 5;
  const height = Math.random() * 5 + 10;
  const xPosition = Math.random() * 100;
  const rotate = Math.random() * 360;

  return (
    <motion.div
      style={{
        position: 'absolute',
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: color,
        top: '-10%',
        left: `${xPosition}vw`,
      }}
      initial={{ y: -100, opacity: 1, rotate }}
      animate={{ y: '100vh', opacity: 0, rotate: rotate + 360 }}
      transition={{ duration: Math.random() * 2 + 2, ease: 'easeIn' }}
    />
  );
};

export const Confetti: FC<ConfettiProps> = ({
  visible = false,
  colors = confettiColors,
  count = 100,
  className,
  styles = {},
}) => {
  const confettiPieces = Array.from({ length: count }, () => (
    <ConfettiPiece key={Math.random()} color={colors[Math.floor(Math.random() * colors.length)]} />
  ));

  return (
    <>
      {
        visible && (
          <div style={styles} className={clsx(className)}>
            {confettiPieces}
          </div>
        )
      }
    </>
  );
};
