import { StashedAuthAndTransfer } from "./StashedAuthAndTransfer";
import { StashedAuthAndTransferOKX } from "./ZKloginAndTransferToOKX";

export const Auth = () => {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  const parsedData = JSON.parse(params.get("state") ?? "");

  if (parsedData.isOkx) {
    localStorage.setItem("receiverAddress", parsedData.recieverAddress);
    return <StashedAuthAndTransferOKX />;
  } else {
    return <StashedAuthAndTransfer />;
  }
};
