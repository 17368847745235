import { action, computed, observable } from "mobx";
import type RootStore from "./RootStore";
import { BoostOption } from "@/services/types";
import { gameStore, toastManagerStore } from "@/store";
import { backend } from "@/services";

interface State {
  level: number;
}

interface Options {
  id: number;
  name: string;
  description: string;
  initialEffect: number;
  levelEffect: number;
  initialCost: number;
  costMultipler: number;
  maxLevel: number | null;
}

export interface SuccessToast {
  text: string;
  icon: string;
}

export default abstract class BoostPassiveAbstractStore {
  rootStore: RootStore;
  @observable private accessor state: State = { level: 0 };
  @observable.ref private accessor options: Options | undefined;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action init(bo: BoostOption | undefined) {
    if (!bo) {
      throw new Error("Empty options for passive boost");
    }
    if (bo.initialEffect && bo.lvlupEffect && bo.lvlupCost) {
      this.options = {
        id: bo.externalId,
        name: bo.nameEn,
        description: bo.descriptionEn,
        initialEffect: bo.initialEffect,
        levelEffect: bo.lvlupEffect,
        initialCost: bo.initialCost,
        maxLevel: bo.maxLevel,
        costMultipler: bo.lvlupCost,
      };
    } else {
      throw new Error("incorrect passive boost options");
    }
  }

  @action initState(level: number) {
    this.state.level = level;
  }

  @computed public get level() {
    return this.state.level;
  }

  @computed public get maxLevel() {
    return this.options!.maxLevel;
  }

  @computed public get isMaxed() {
    return this.level === this.maxLevel;
  }

  //@computed public get options() {
  //  return this.levels[this.level];
  //}

  //@computed public get next() {
  //  if (this.levels[this.level + 1]) {
  //    return this.levels[this.level + 1];
  //  } else {
  //    return null;
  //  }
  //}

  @computed public get id() {
    return this.options!.id;
  }

  @computed public get has() {
    return this.level > 0;
  }

  @computed public get cost() {
    return (
      this.options!.initialCost * this.options!.costMultipler ** this.level
    );
  }

  @computed public get effect() {
    return this.options!.initialEffect + this.level * this.options!.levelEffect;
  }

  @computed public get effectPerLevel() {
    return this.options!.levelEffect;
  }

  @computed public get nextEffect() {
    return this.effect + this.options!.levelEffect;
  }

  @computed public get name() {
    return this.options!.name;
  }

  @computed public get desc() {
    return this.options!.description;
  }

  //public abstract get humanNowEffect(): string;
  //public abstract get humanNextEffect(): string;

  @computed public get canBuy() {
    if (!this.isMaxed) {
      return gameStore.balance >= this.cost;
    } else {
      return false;
    }
  }

  @action.bound buy(successToast?: SuccessToast) {
    if (this.canBuy && this.cost) {
      backend.sendBoost(this.id, this.level + 1);
      gameStore.addBalance(-this.cost);
      this.state.level++;
      successToast &&
        toastManagerStore.addToast(successToast.text, successToast.icon);
    }
  }
}
