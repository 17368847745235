import { FC } from "react";
import { observer } from "mobx-react-lite";
import { IComputedValue } from "mobx";

import { calculateTimeLeft } from "@/utils";
import { BoostItem } from "../../BoostItem";
import { TBooster } from "../../Boost";

import styles from "./DailyBooster.module.sass";

type DailyBoosterProps = {
  onClick: (boostItem: TBooster, isActive: boolean) => () => void;
  boosters: IComputedValue<TBooster[]>
};

export const DailyBooster: FC<DailyBoosterProps> = observer(({
  onClick,
  boosters,
}) => {
  return (
    <>
      {
        boosters.get().map((booster, i) => (
          <BoostItem
            title={booster.title}
            isMaxLevel={booster.isMaxLevel || !booster.isAvailable}
            onClick={onClick(booster, Boolean(booster.isAvailable))}
            hint={booster.hint}
            key={booster.type + i}
          >
            {!booster.isActive && booster.amount && booster.amount > 0 && booster.isAvailable && (
              <p className={styles.info}>
                {`${booster.availableAmount} of ${booster.amount} available`}
              </p>
            )}
            {booster.amount && !booster.isAvailable && booster.cooldown && (
              <p className={styles.info}>{`${
                calculateTimeLeft(booster.cooldown)
              }  left`}</p>
            )}
          </BoostItem>
        ))
      }
    </>
  )
});
