import { useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { LeagueProgressBar } from "./LeagueProgressBar";
import { LeagueFace } from "./LeagueFace";
import { LeagueUser } from "./LeagueUser";
import { leaguesLeaderboardStore, leagueStore } from "@/store";
import starvingLeague from "@/assets/capybara-images/starvingLeague.png";
import { findLeagueConfig } from "@/utils/leagueConfig";

import styles from "./styles.module.css";

export const Leaderboard = observer(() => {
  const { leagueName, leagues, current } = leagueStore;
  const {
    selectedLeague,
    players,
    loading,
    playerPosition,
    playerCoins,
    selectLeague,
    getPlayersLeaderboard,
  } = leaguesLeaderboardStore;

  const onLeagueClick = (leagueId: number) => {
    if (loading) return;
    selectLeague(leagueId);
    getPlayersLeaderboard(leagueId);
  };

  const currentLeague = leagues.find(
    (league) => league.level === selectedLeague
  );
  const currentImage =
    (currentLeague &&
      findLeagueConfig(currentLeague.leagueNameEn)?.leagueFace) ||
    starvingLeague;

  const userImage =
    (current && findLeagueConfig(current.leagueNameEn)?.leagueFace) ||
    starvingLeague;

  const userLeagueVisible =
    !!players.length && current.level === selectedLeague;

  useEffect(() => {
    selectLeague(current.level);
    getPlayersLeaderboard(current.level);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        paddingBottom: players.length > 3 ? "160px" : "40px",
      }}
    >
      <div className={styles.title}>
        <p className={styles.coinsText}>{leagueName}</p>
        <LeagueProgressBar />
      </div>
      <div className={styles.leagues}>
        {leagues.map((item) => (
          <LeagueFace
            key={item.leagueNameEn}
            level={item.level}
            selectedLeague={selectedLeague}
            leagueName={item.leagueNameEn}
            img={
              findLeagueConfig(item.leagueNameEn)?.leagueFace ?? starvingLeague
            }
            onClick={() => onLeagueClick(item.level)}
          />
        ))}
      </div>
      <div className={styles.dailyTasksWrapper}>
        <p className={styles.tasksTitle}>Leaderboard</p>
        <div className={clsx(styles.leaderboardWrapper)}>
          {!loading &&
            players.map((item, index) => (
              <LeagueUser
                img={currentImage || starvingLeague}
                userName={item.username}
                index={index}
                position={item.position}
                coins={item.coins}
                key={item.username + index}
                isUser={false}
              />
            ))}
          {loading && <div className={clsx(styles.spinner)}></div>}
        </div>
      </div>
      {userLeagueVisible && (
        <div className={styles.userRecordWrapper}>
          <div className={styles.userRecordContainer}>
            <LeagueUser
              img={userImage}
              userName={Telegram.WebApp.initDataUnsafe.user?.username ?? ""}
              index={playerPosition || 0}
              position={playerPosition || 0}
              coins={playerCoins}
              isUser
            />
          </div>
        </div>
      )}
    </div>
  );
});
