import { FC } from "react";
import { observer } from "mobx-react-lite";

import capyBag from "@/assets/capybara-images/capybag-shine.png";
import Star1 from "@/assets/icons/star1.svg?react";
import Star2 from "@/assets/icons/star2.svg?react";
import { Button } from "@/components/Button";

import styles from './styles.module.sass';
import { DialogComponents } from "@/components/Dialog";

type OpenMoneyBagProps = {
  isOpen: boolean;
  onAction: VoidFunction;
  onClose: VoidFunction;
  onListForSale: VoidFunction;
}

export const OpenMoneyBag: FC<OpenMoneyBagProps> = observer(({
  isOpen,
  onAction,
  onClose,
  onListForSale,
}) => {
  return (
    <>
      <DialogComponents.Container
        isOpen={isOpen}
        onClose={onClose}
        onAction={onAction}
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
      >
        {({ handleClose, handleAction }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              className={styles.modalCloser}
              onClick={handleClose}
            />
            <div className={styles.capybag}>
              <Star1 className={styles.star}/>
              <Star2 className={styles.star}/>
              <img alt="capybag" src={capyBag} style={{maxWidth: "323px"}}/>
            </div>
            <DialogComponents.TextContainer className={styles.textContainer}>
              <DialogComponents.Title className={styles.modalTitle}>
                {`You got a Money Bag!`}
              </DialogComponents.Title>
              <DialogComponents.Text className={styles.modalText}>
                {`You can sell it on NFT marketplace now or open it later (Q1 2025) to get a reward`}
              </DialogComponents.Text>
            </DialogComponents.TextContainer>
            <div className={styles.controls}>
              <Button
                onClick={handleAction}
                label="Got It"
                styleType="primary"
                block={true}
              />
              <Button
                onClick={onListForSale}
                label={"List for Sale"}
                styleType="outline"
                block={true}
              />
            </div>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
    </>
  )
});