export function getUsername() {
  if (Telegram.WebApp.initDataUnsafe.user) {
    const firstName = Telegram.WebApp.initDataUnsafe.user.first_name;
    const lastName = Telegram.WebApp.initDataUnsafe.user.last_name;
    if (!lastName) return firstName;
    return `${firstName} ${lastName}`;
  }
  return "username";
}

export const userId = Telegram.WebApp.initDataUnsafe.user?.id;
