import { FC } from "react";

import Coin from "@/assets/capybara-images/coin.svg?react";
import { Badge } from "@/components/Badge";
import { BoostItem } from "../../BoostItem";
import { observer } from "mobx-react-lite";
import { TBooster } from "../../Boost";
import { IComputedValue } from "mobx";

type BoosterProps = {
  onClick: (boostItem: TBooster, isActive: boolean) => () => void;
  boosters: IComputedValue<TBooster[]>;
};

export const Booster: FC<BoosterProps> = observer(({
  onClick,
  boosters,
}) => {
  return (
    <>
      {
        boosters.get().map((booster, i) => (
          <BoostItem
            title={booster.title}
            isMaxLevel={booster.isMaxLevel || !booster.isAvailable}
            onClick={onClick(booster, Boolean(!booster.isMaxLevel && booster.isAvailable))}
            hint={booster.hint}
            key={booster.type + i}
          >
            <Badge color="blue" visible={Boolean(booster.cost && !booster.isMaxLevel)}>
              {`${booster?.cost?.toLocaleString("en-US") || ''}`}
              <Coin width={16} height={16} />
            </Badge>
            <Badge color="darkblue" size="m" visible={booster.isMaxLevel && !booster.amount}>
              {`🔒 Max level reached`}
            </Badge>
            <Badge color="lightblue" visible={booster.level !== undefined && booster.level >= 0 && !booster.isMaxLevel}>
              {`${booster.level} lvl`}
            </Badge>
          </BoostItem>
        ))
      }
    </>
  )
});
