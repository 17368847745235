import { useState } from "react";
import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

export type WalletInputProps = {
  name: string;
  value?: string;
  hint?: string;
  wrap?: boolean;
  label?: string;
  error?: string;
  onChange?: (value: string) => void;
  labelSize?: "xl" | "m";
};

const WalletInput = observer(
  ({
    label,
    hint,
    wrap,
    name,
    value,
    onChange,
    labelSize = "m",
    error,
  }: WalletInputProps) => {
    const [inputedValue, setInputedValue] = useState<string>();

    return (
      <div className={styles.walletWrapper}>
        <div className={styles.walletInput}>
          {label && !inputedValue?.toString() && (
            <label
              className={clsx(styles.label, {
                [styles[labelSize]]: true,
              })}
              htmlFor="amount"
            >
              {label}
            </label>
          )}
          {!wrap ? (
            <input
              id="amount"
              name={name}
              className={clsx(styles.input, {
                [styles.error]: error,
              })}
              value={inputedValue ?? value}
              onChange={(e) => {
                setInputedValue(e.target.value);
                onChange?.(e.target.value);
              }}
            />
          ) : (
            <textarea
              className={clsx(styles.input, {
                [styles[labelSize]]: true,
              })}
              value={value}
              onChange={(e) => {
                const val = e?.target?.value ?? "";
                onChange && onChange(val);
                setInputedValue(val);
              }}
              name={name}
            />
          )}
        </div>
        {hint && <p className={styles.hint}>{hint}</p>}
        {error && inputedValue?.toString() && (
          <p
            className={clsx(styles.hint, {
              [styles.error]: error,
            })}
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

export default WalletInput;
