import { useCallback } from "react";
import rootStore from "@/store";
import { HapticType } from "@/store/RootStore";
import { isTelegram } from "@/utils";

const useHapticFeedback = (isDoubleHaptic?: boolean) => {
  const triggerHapticFeedback = useCallback(
    (type: HapticType = rootStore.hapticType) => {
      if (
        isTelegram &&
        type !== "off" &&
        window.Telegram &&
        Telegram.WebApp &&
        Telegram.WebApp.HapticFeedback
      ) {
        Telegram.WebApp.HapticFeedback.impactOccurred(type);
        if (isDoubleHaptic) {
          Telegram.WebApp.HapticFeedback.impactOccurred(type);
        }
      }
    },
    []
  );

  return triggerHapticFeedback;
};

export default useHapticFeedback;
