import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import { menuItems } from "@/constants";
import { Menu } from "@/components/Menu";
import useHapticFeedback from "@/hooks/useHapticFeedback";
import { isTelegram } from "@/utils";
import { observer } from "mobx-react-lite";
import { boostTapStore, leagueStore } from "@/store";
import turboBoostImg from "@/assets/capybara-images/turboBoostBg.png";

const getBackgroundAndWallpaper = (path: string, level: number) => {
  const item = menuItems.find((item) => item.path === path);
  if (item) {
    return {
      backgroundColor: item.backgroundColor(level),
      wallpaper: item.wallpaper(level),
      headerColor: item.headerColor(level),
    };
  }
  return { backgroundColor: "", wallpaper: "", headerColor: "" };
};

const CapybaraLayout: React.FC<{
  children: React.ReactNode;
  isHideMenu?: boolean;
}> = observer(({ children, isHideMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [wallpaper, setWallpaper] = useState<string>("");
  const triggerHapticFeedback = useHapticFeedback();
  const { level } = leagueStore;
  const { isActive } = boostTapStore;

  useEffect(() => {
    const { backgroundColor, wallpaper, headerColor } =
      getBackgroundAndWallpaper(location.pathname, level);
    setBackgroundColor(backgroundColor);
    setWallpaper(wallpaper);
    isTelegram && headerColor && Telegram.WebApp.setHeaderColor(headerColor);
  }, [location.pathname, level]);

  const handleSelect = (index: number) => {
    triggerHapticFeedback();
    navigate(menuItems[index].path);
  };

  const selectedComponent = menuItems.findIndex(
    (item) => item.path === location.pathname
  );

  return (
    <div
      style={{
        background: backgroundColor,
      }}
      className={styles.layout}
    >
      <div
        className={`${
          location.pathname === "/" || location.pathname === "/nftMint"
            ? styles.bottomWallpaper
            : styles.topWallpaper
        } ${styles.wallpaper}`}
        style={{
          backgroundImage:
            isActive && location.pathname === "/"
              ? `url(${turboBoostImg})`
              : `url(${wallpaper})`,
        }}
      >
        {children}
        {!isHideMenu && (
          <Menu
            components={menuItems}
            selectedComponent={selectedComponent}
            onSelect={handleSelect}
          />
        )}
      </div>
    </div>
  );
});

export default CapybaraLayout;
