import { isBackend } from "@/utils";
import Backend from "./Backend";
import FakeBackend from "./FakeBackend";
import RealBackend from "./RealBackend";

let backend: Backend;

if (isBackend) {
  backend = new RealBackend();
} else {
  backend = new FakeBackend();
}

export { backend };
