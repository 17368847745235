import clsx from "clsx";
import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import SendToAdderess from "./SendToAdderess";

const WalletTransfer = observer(() => {
  return (
    <div className={styles.content}>
      <div className={styles.navbar}>
        <div
          className={clsx(styles.navbarItem, {
            [styles.active]: true,
          })}
        >
          <p>Send to address</p>
        </div>
      </div>
      <SendToAdderess />
    </div>
  );
});

export default WalletTransfer;
