import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";
import { observer } from "mobx-react-lite";
import { gameStore } from "@/store";

export const CoinsDisplay = observer(() => {
  return (
    <div className={styles.coinsContainer}>
      <Coin />
      <span className={styles.coinsText}>
        {gameStore.balance.toLocaleString("en-US")}
      </span>
    </div>
  );
});
