import "./initializer";
import "@/polyfills";
import "@/locale/i18n";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "@/styles/index.sass";
import "./utils/sentry.ts";
import store from "@/store";
import { BrowserRouter } from "react-router-dom";

store.init();


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);
