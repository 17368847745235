import { FC } from "react";

import Coin from "@/assets/capybara-images/coin.svg?react";
import { Badge } from "@/components/Badge";
import { BoostItem } from "../../BoostItem";
import { observer } from "mobx-react-lite";
import { TBooster } from "../../Boost";
import { IComputedValue } from "mobx";
import { isProduction } from "@/utils";

type AutoFeedBotProps = {
  onClick: (boostItem: TBooster, isActive: boolean) => () => void;
  boosters: IComputedValue<TBooster[]>;
  isDisabled?: boolean;
};

export const AutoFeedBot: FC<AutoFeedBotProps> = observer(
  ({ onClick, boosters, isDisabled }) => {
    return (
      <>
        {boosters.get().map((booster, i) => (
          <BoostItem
            title={booster.title}
            isMaxLevel={booster.isMaxLevel || !booster.isAvailable}
            onClick={
              !isDisabled
                ? onClick(booster, Boolean(booster.isAvailable))
                : undefined
            }
            hint={isProduction ? 'Auto feed bot will be available soon' : booster.hint}
            key={booster.type + i}
          >
            {isProduction ? (
              <Badge color="darkblue" visible={Boolean(!booster.isActive)}>
                🔒 soon
              </Badge>
            ) : (
              <>
                <Badge color="blue" visible={Boolean(!booster.isActive)}>
                  {`${(!booster.isAvailable && `🔒`) || ""} ${
                    booster?.cost?.toLocaleString("en-US") || ""
                  }`}
                  <Coin width={16} height={16} />
                </Badge>
                <Badge
                  color={booster.isActive ? "lightblue" : "darkblue"}
                  size="s"
                >
                  {booster.isActive ? "on" : "off"}
                </Badge>
              </>
            )}
          </BoostItem>
        ))}
      </>
    );
  }
);
