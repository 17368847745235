import { useCallback, useEffect, useRef } from "react";
import styles from "./styles.module.sass";
import { ReferralLevelList } from "./ReferralLevelsList";
import { ReferralUser } from "./ReferralUser";
import { Button } from "@/components/Button";
import { observer } from "mobx-react-lite";
import { inviteShareLink } from "@/constants";
import { friendStore, leagueStore } from "@/store";
import { findLeagueConfig } from "@/utils/leagueConfig";
import ReferralIcon from "@/assets/capybara-images/referral.svg?react";
import clsx from "clsx";

const ITEM_HEIGHT = 40;

export const Referral = observer(() => {
  const {
    friends,
    loading,
    loaded,
    totalCount,
    pagination,
    loadingMore,
    nextPage,
    refresh,
  } = friendStore;

  const friendsRef = useRef<HTMLDivElement>(null);
  const userId = Telegram.WebApp.initDataUnsafe.user?.id;
  const link = inviteShareLink.replace("{userId}", btoa(userId + ""));
  const handleShareClick = () => {
    if (Telegram?.WebApp) {
      Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${link}&text=%0AJoin me on the Capybara clicker game!%0A🎉 Let's earn coins and have fun together!%0AUse my invite link to start playing now and claim your rewards!`
      );
    }
  };

  const handleScroll = useCallback((e: Event) => {
    if (pagination.current >= pagination.to) return;

    const target = e.target as HTMLDivElement;

    const preloadPosition = ((pagination.current * pagination.limit) * ITEM_HEIGHT) - 400;
    
    if (target.scrollTop > preloadPosition) {
      nextPage();
      friendStore.loadMore();
    }

  } , [pagination, nextPage]);

  useEffect(() => {
    const el = friendsRef.current;
    el?.addEventListener('scroll', handleScroll);
    return () => el?.removeEventListener('scroll', handleScroll);
  }, [friendsRef, handleScroll]);

  useEffect(() => {
    if (!loading && !loaded) {
      refresh();
    }
  });
  
  return (
    <div
      style={{
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        paddingBottom: "90px",
      }}
    >
      <div className={styles.title}>
        <p className={styles.coinsText}>Referral</p>
        <ReferralLevelList />
      </div>
      <div className={styles.referButonsContainer}>
        <Button
          styleType="primary"
          label="Refer a Friend"
          onClick={() => handleShareClick()}
        />
        <p className={styles.referButonsHint}>
          Please note, only friends who have created
          <br /> Sui wallets will be counted as successful referrals.
        </p>
        {/* <Button styleType="secondary" label="Join Team" /> */}
      </div>
      <div className={styles.dailyTasksWrapper}>
        <div className={styles.friendsTitleWrapper}>
          <p className={styles.tasksTitle}>Invited Friends</p>
          <p className={styles.friendsCount}>{totalCount}</p>
        </div>

        <div
          className={clsx(styles.referralWrapper, {
            [styles.referralWrapperWithOutUsers]: friends?.length === 0,
          })}
          ref={friendsRef}
        >
          {friends?.length === 0 && loaded && (
              <div className={styles.blockedLeagueContainer}>
                <div className={styles.blockedLeagueImgContainer}>
                  <ReferralIcon width={56} height={56} />
                </div>
                <p className={styles.blockedLeagueText}>No invited friends yet</p>
              </div>
            )}
            {loading && (
              <div className={styles.loaderContainer}>
                <div className={styles.spinner}></div>
              </div>
            )}
            {friends &&
              loaded &&
              friends?.length > 0 &&
              friends.map((item, i) => {
                const currentUserLeague = leagueStore.leagues.find(
                  (l) => l.level === item?.leagueLevel
                );

                const img = findLeagueConfig(
                  currentUserLeague?.leagueNameEn ?? ""
                )?.leagueFace;

                return (
                  img &&
                  currentUserLeague && (
                    <ReferralUser
                      key={item.username + i}
                      img={img}
                      userName={item.username}
                      leagueName={currentUserLeague?.leagueNameEn}
                    />
              ));
          })}
        </div>
        {loadingMore && <div className={clsx(styles.spinner, styles.spinner__more)}></div>}
      </div>
    </div>
  );
});
