import ky from "ky";
import { useEffect, useState } from "react";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import walletSuccess from "@/assets/capybara-images/walletSuccess.png";
import walletBg from "@/assets/capybara-images/walletBg.png";
import styles from "../styles.module.sass";
import { useAuthCallback, useEnokiFlow, useZkLogin } from "@mysten/enoki/react";
import { toastManagerStore } from "@/store";
import { Dialog } from "@/components/Dialog";
import WalletCopy from "@/assets/capybara-images/walletCopy.svg?react";
import { balance, shortenText } from "@/utils";
import { Transaction } from "@mysten/sui/transactions";
import Loader from "@/layout/Loader";
import { handleClose } from "../walletUtils";
import { useSuiClient } from "@mysten/dapp-kit";

export const StashedAuthAndTransferOKX = () => {
  const { handled } = useAuthCallback();
  const { address } = useZkLogin();
  const [enokiBalance, setEnokiBalance] = useState<string | null>(null);
  const [isOpenTransfer, setIsOpenTransfer] = useState(false);
  const [isTransferComplete, setIsTransferComplete] = useState(false);
  const client = useSuiClient();

  const [isLoader, setIsLoader] = useState(false);
  const enokiFlow = useEnokiFlow();

  function handleTransferClick() {
    if (Number(enokiBalance) > 4_000_000) {
      setIsLoader(true);
      enokiFlow
        .getKeypair({
          network: "mainnet",
        })
        .then(async (keypair) => {
          const txb = new Transaction();
          const [coin] = txb.splitCoins(txb.gas, [
            Number(enokiBalance) - 4_000_000,
          ]);

          txb.setSender(keypair.getPublicKey().toSuiAddress());
          txb.transferObjects(
            [coin],
            localStorage.getItem("receiverAddress") as string
          );

          client.signAndExecuteTransaction({
            signer: keypair,
            transaction: txb,
          });

          setIsLoader(false);
          setIsTransferComplete(true);
        });
    } else {
      setIsLoader(false);
      setIsTransferComplete(true);
    }
  }

  useEffect(() => {
    if (handled && address) {
      const rpcRequest = {
        jsonrpc: "2.0",
        method: "suix_getBalance",
        params: [address],
        id: 1,
      };

      ky.post("https://fullnode.mainnet.sui.io", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(rpcRequest),
      }).then((resp) => {
        resp.json().then((result) => {
          const typedResult = result as {
            jsonrpc: string;
            result: { totalBalance: string };
            id: number;
          };

          setIsOpenTransfer(true);
          setEnokiBalance(typedResult.result.totalBalance);
        });
      });
    }
  }, [handled, address]);

  return (
    <>
      {isLoader && <Loader />}
      <Dialog
        modalWallpaper={walletBg}
        isOpen={isOpenTransfer}
        onClose={() => {
          setIsOpenTransfer(false);
          handleTransferClick();
        }}
        onAction={() => {
          setIsOpenTransfer(false);
          handleTransferClick();
        }}
        title={"Old wallet successfully connected!"}
        text={
          <>
            <div
              onClick={() =>
                navigator.clipboard
                  .writeText(address ?? "")
                  .then(() =>
                    toastManagerStore.addToast("Address copied to clipboard")
                  )
              }
              className={styles.walletInfoContaienr}
            >
              <div className={styles.walletFinanceBlock}>
                <p>{shortenText(address ?? "undefined", 8)}</p>
                <WalletCopy />
              </div>
              <div className={styles.walletFinanceBlock}>
                <p>{balance(enokiBalance ?? "0")} SUI</p>
              </div>
            </div>
            <p className={styles.walletText}>
              Let’s proceed by creating a new wallet
            </p>
          </>
        }
        modalTitleColor={"#C0C3FF"}
        modalIcon={iosWalletImg}
        buttonText={"Transfer SUI to OKX"}
      />
      <Dialog
        modalWallpaper={walletBg}
        isOpen={isTransferComplete}
        onClose={() => setIsOpenTransfer(false)}
        onAction={() => {
          sessionStorage.clear();
          handleClose();
        }}
        title={"Congratulations!"}
        text={
          <p className={styles.walletText}>
            Your SUI balance has been transferred to the new wallet! Use it
            in-game or withdraw it to any external wallet
          </p>
        }
        modalTitleColor={"#C0C3FF"}
        modalIcon={walletSuccess}
        buttonText={"Return to Telegram"}
      />
    </>
  );
};
