import { action, computed, observable } from "mobx";

import img from "@/assets/capybara-images/capynft.png"

type Bag = number;

interface State {
  bags: Bag[],
  recieved: {
    modal: boolean,
    data: { name: string, img: string },
  }
}

export default class LootboxesStore {
  @observable private accessor state: State = {
    bags: [],
    recieved: {
      modal: false, data: { name: '', img }
    }
  };

  @computed public get receiveModal() {
    return this.state.recieved.modal;
  }

  @computed public get receivedData() {
    return this.state.recieved.data;
  }

  @computed public get canBuy() {
    return true;
  }

  @computed public get hasLootboxes() {
    return this.canBuy && !!this.state.bags.length;
  }

  @action.bound buy(count: Bag[]) {
    this.state.bags = [...this.state.bags, ...count];
  }

  @action.bound openReceiveModal(state: boolean) {
    this.state.recieved.modal = state;
  }
}

