import { MissionResponse, TaskOption } from "@/services/types";
import { observable, action, computed, flow } from "mobx";
import { backend } from "@/services";
import { gameStore, toastManagerStore } from ".";
//import { enInviteText, ruInviteText, inviteShareLink } from "@/constants";
import { isDevelopment, isProduction } from "@/utils";
import { cdnProduction, cdnStage } from "@/constants";

export interface MissionOptions extends TaskOption {
  id: number;
}

export interface SuccessToast {
  text: string;
  icon: string;
  isBlack?: boolean;
}

interface MissionState {
  done: boolean;
  visit: boolean;
  loading: boolean;
}

const cdn = isProduction ? cdnProduction : cdnStage;
export default class Mission {
  id: number;
  campaignId: number | null;
  private accessor options: MissionOptions;
  @observable protected accessor state: MissionState;

  constructor(options: MissionOptions) {
    this.state = { done: false, visit: false, loading: false };
    this.id = options.id;
    this.campaignId = options.campaignExternalId;
    this.options = options;
  }

  @action initState(done: boolean) {
    this.state.done = done;
  }

  @computed public get reward() {
    return this.options.rewardCoins;
  }

  @computed public get isActive() {
    return this.options.isActive;
  }

  @computed public get isDone() {
    return this.state.done;
  }

  @computed public get isLoading() {
    return this.state.loading;
  }

  @computed public get show() {
    return this.isActive || this.isDone;
  }

  @computed public get icon() {
    return `${cdn}/${this.options.icon}`;
  }

  @computed public get type() {
    return this.options.type;
  }

  @computed public get action() {
    return this.options.action;
  }

  @computed public get name() {
    return this.options.nameEn;
  }

  @computed public get desc() {
    return this.options.descriptionEn;
  }

  @computed public get link() {
    return this.options.link;
  }

  @computed public get isVisit() {
    return this.state.visit;
  }

  @computed public get pin() {
    return this.options.pin;
  }

  @action.bound go(isToast?: boolean, successToast?: SuccessToast) {
    this.state.visit = true;
    if (this.action === "LINK") {
      if (isDevelopment) return;
      if (this.link?.includes("t.me")) {
        Telegram.WebApp.openTelegramLink(this.link!);
      } else {
        Telegram.WebApp.openLink(this.link!);
      }
      setTimeout(() => {
        !this.state.done && this.check(isToast, successToast);
      }, 10000);
    } else if (this.action === "CHANNEL") {
      console.warn("Opening tg link", this.link!);
      Telegram.WebApp.openTelegramLink(this.link!);
      setTimeout(() => {
        console.warn("Checking mission", this.id);
        !this.state.done && this.check(isToast, successToast);
      }, 10000);
    } else {
      throw new Error("Unknown mission go" + this.id);
    }
  }

  @flow.bound *check(isToast?: boolean, successToast?: SuccessToast) {
    if (this.isDone) return;
    this.state.loading = true;
    const result: MissionResponse = yield backend.sendMission(this.id);
    if (result.isCompleted) {
      gameStore.addBalance(this.reward);
      this.state.done = true;
      isToast &&
        toastManagerStore.addToast(
          successToast?.text ?? "",
          successToast?.icon ?? "",
          successToast?.isBlack ?? false
        );
    }
    this.state.loading = false;
  }
}
