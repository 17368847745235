import { FC, PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

import styles from './Card.module.sass';

type CardProps = {
  className?: string;
  width?: string;
  height?: string;
  alignContent?: 'center';
  shineBg?: boolean;
  backgroundColor?: string;
  background?: string;
  borderRadius?: string;
  onClick?: VoidFunction;
}

type CountProps = {
  count: number;
  width?: string;
  height?: string;
  position?: { top: string, right: string };
  fontSize?: string;
  className?: string;
}

export const Count: FC<CountProps> = observer(({
  count,
  className = '',
  width = 'auto',
  height = 'auto',
  fontSize = '16px',
  position = { top: 0, right: 0 },
}) => {
  return (
    <>
      {!!count && (
        <i style={{
          width,
          height,
          fontSize,
          ...position,
        }} className={clsx(styles.card__count, className)}>
          {count}
        </i>
        )
      }
    </>
  )
});

export const Card: FC<PropsWithChildren & CardProps> = observer(({
  children,
  className = '',
  width,
  height,
  alignContent = 'center',
  shineBg = false,
  backgroundColor,
  borderRadius = "36px",
  background,
  onClick = () => null,
}) => {
  return (
    <div
      onClick={onClick}
      style={({ ...({width, height, borderRadius, background, backgroundColor }) })}
      className={clsx(styles.card, className, {
        [styles[`card_${alignContent}`]]: !!alignContent,
        [styles.card_shine]: !!shineBg,
      })}
    >
      {children}
    </div>
  )
});
