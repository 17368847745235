import React from "react";
import styles from "./styles.module.css";
import { renderTextWithLineBreaks } from "@/utils/renderTextWithLineBreaks";

interface ReferralLevelItemProps {
  img: string;
  hint: string;
  reward: number;
  index: number;
}

export const ReferralLevelItem: React.FC<ReferralLevelItemProps> = ({
  img,
  hint,
  index,
  reward,
}) => {
  return (
    <div
      className={styles.referralLevelContainer}
      style={{
        margin: index > 0 ? "0 12px" : undefined,
      }}
    >
      <div className={styles.referral}>
        <div className={styles.referralDescription}>
          <img src={img} alt={"user"} />
          <p>{`Level ${index + 1}`}</p>
        </div>

        <div className={styles.coinsAmount}>
          <p>{`${reward}%`} </p>
        </div>
      </div>
      <div className={styles.referralHintContainer}>
        <p
          style={{
            fontSize: index > 0 ? "13px" : undefined,
          }}
        >
          {renderTextWithLineBreaks(hint)}
        </p>
      </div>
    </div>
  );
};
