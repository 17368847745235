// QueryWrapper.tsx
import { useEffect, useState, ReactNode } from "react";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import { handleClose } from "../walletUtils";
import ky from "ky";
import { apiProduction, apiStage, isProdUrl } from "@/constants";
import Loader from "@/layout/Loader";

export type SignType = {
  ok: boolean;
  signature: string;
  valid_until: number;
  balance: number;
  league: number;
  wallet: string;
};

interface QueryWrapperProps<T> {
  children: (props: {
    queryParams: T;
    handleClose: () => void;
    sign?: SignType;
  }) => ReactNode;
  requiredParams?: (keyof T)[];
  nonRequiredParams?: (keyof T)[];
  isSign?: boolean;
}

export const QueryWrapper = <T extends Record<string, string>>({
  children,
  requiredParams = [],
  nonRequiredParams = [],
  isSign,
}: QueryWrapperProps<T>) => {
  const [sign, setSign] = useState<SignType | undefined>();
  const [isLoader, setIsLoader] = useState(false);
  const currentAccount = useCurrentAccount();
  const [queryParams, setQueryParams] = useState<Partial<T>>({});
  const [isValid, setIsValid] = useState(true);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(true);
  const base = isProdUrl ? apiProduction : apiStage;

  useEffect(() => {
    localStorage.removeItem("stashed:recentAddress");
    localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (isSign) {
      setIsLoader(true);
      ky.get(base + `/api/nft/daily_sign`, {
        headers: {
          Authorization: `Bearer ${urlParams.get("token")}`,
        },
      })
        .then((result) => result.json() as Promise<SignType>)
        .then((data) => {
          setSign(data);
          setIsLoader(false);
        });
    }
  }, [isSign]);

  useEffect(() => {
    if (currentAccount?.address) {
      setStashedWalletIsOpen(false);
    }
  }, [currentAccount]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params: Partial<T> = {};

    requiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      } else {
        setIsValid(false);
      }
    });

    nonRequiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      }
    });

    setQueryParams(params);
  }, [requiredParams, nonRequiredParams]);

  return isValid ? (
    <>
      {isLoader && <Loader />}
      <ConnectModal
        open={stashedWalletIsOpen}
        trigger={<></>}
        onOpenChange={() => {}}
      />
      {!stashedWalletIsOpen &&
        (isSign ? sign : true) &&
        children({ queryParams: queryParams as T, handleClose, sign })}
    </>
  ) : (
    "some params are missing"
  );
};
