import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";
import Info from "@/assets/layout/rating/info.svg?react";
import { observer } from "mobx-react-lite";

import NftBorder from "@/assets/capybara-images/nftBorder.png";
import clsx from "clsx";
import { Button } from "../Button";
import SuiImg from "@/assets/capybara-images/sui-80.png";
import { useNavigate } from "react-router-dom";
import { isProduction } from "@/utils";
import {
  QueryWrapper,
  SignType,
} from "../CreateWallet/components/QueryWrapper";
import { useEffect, useState } from "react";
import {
  useCurrentAccount,
  useSignAndExecuteTransaction,
} from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import { isAndroid, isIOS } from "@/utils/handleExpandPage";
import { buildCheckinTx, buildMintNftTx } from "@/utils/mintNft";

import {
  apiProduction,
  apiStage,
  capybaraLevels,
  isProdUrl,
  PACKAGE_ADDRESS,
  REGISTRY_ADDRESS,
  TREASURY_ADDRESS,
} from "@/constants";
import ky from "ky";
import { handleClose } from "../CreateWallet/walletUtils";
import SentryObject from "@/utils/sentry";

const handleSuccess = (
  result: {
    digest: string;
  },
  setIsLoader: (value: boolean) => void,
  setIsError: (value: boolean) => void,
  base: string,
  userId: string,
  token: string,
  isUpdate?: boolean
) => {
  if (result.digest) {
    ky.post(base + `/api/nft/${isUpdate ? "update_tx" : "mint_tx"}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      json: {
        tx_digest: result.digest,
        user_id: userId,
      },
    })
      .then((result) => result.json() as Promise<{ ok: boolean; err: string }>)
      .then((data) => {
        if (data.ok) {
          if (isIOS) {
            const url = new URL(window.location.href);
            url.search = "";
            window.history.replaceState(null, "", url.toString());
            handleClose();
            setIsLoader(false);
          } else {
            if (isAndroid) {
              localStorage.setItem("nftShouldMint", "true");
            }
            window.close();
          }
        } else {
          setIsLoader(true);
          SentryObject?.captureException(data.err, {
            extra: { token, userId, isUpdate },
          });
          setIsError(true);
        }
      });
  }
};

export const MintingNft = observer(() => {
  const currentAccount = useCurrentAccount();
  const [updatedAt, setUpdatedAt] = useState<number | null>(null);
  const [capybaraNftStatus, setCapybaraNftStatus] = useState<
    "MINTING" | "UPDATABLE"
  >("MINTING");
  const navigate = useNavigate();
  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction();
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const base = isProdUrl ? apiProduction : apiStage;

  const transfer = ({
    sign,
    reciever,
    token,
    userId,
    isUpdate,
    nftId,
  }: {
    sign: SignType;
    reciever: string;
    token: string;
    userId: string;
    isUpdate?: boolean;
    nftId?: string;
  }) => {
    setIsLoader(true);
    isError && setIsError(false);

    if (!transacrionInWork) {
      if (sign.ok) {
        const signature = new Uint8Array(Buffer.from(sign.signature, "base64"));
        let nftTx;

        if (isUpdate && nftId) {
          nftTx = buildCheckinTx({
            functionParams: {
              nftAddress: nftId,
              treasury: TREASURY_ADDRESS,
              registry: REGISTRY_ADDRESS,
              fee: 0,
              validUntil: sign.valid_until,
              points: sign.balance,
              league: sign.league,
              signature: signature,
            },
            packageAddress: PACKAGE_ADDRESS,
            userAddress: reciever,
          });
        } else {
          nftTx = buildMintNftTx({
            packageAddress: PACKAGE_ADDRESS,
            sigValidUntil: sign.valid_until,
            points: sign.balance,
            league: sign.league,
            signature: new Uint8Array(Buffer.from(sign.signature, "base64")),
            registryAddress: REGISTRY_ADDRESS,
          });
        }

        signAndExecuteTransaction(
          {
            transaction: nftTx,
            chain: "sui:mainnet",
          },
          {
            onSuccess: (result) =>
              handleSuccess(
                result,
                setIsLoader,
                setIsError,
                base,
                userId,
                token,
                isUpdate
              ),
            onError: (error) => {
              SentryObject?.captureException(error, {
                extra: { token, userId, nftTx, isUpdate, nftId },
              });
              console.log("error", error, token, userId, nftTx);
              setIsLoader(false);
              setIsError(true);
              throw error;
            },
          }
        );
      }

      setTransacrionInWork(true);
    }
  };

  useEffect(() => {
    if (
      updatedAt &&
      (isProduction
        ? updatedAt + 24 * 3600 < Date.now() / 1000
        : updatedAt + 60 < Date.now() / 1000)
    ) {
      setCapybaraNftStatus("UPDATABLE");
    }
  }, [updatedAt]);

  useEffect(() => {}, []);

  return (
    <QueryWrapper
      requiredParams={[
        "earn",
        "leagueName",
        "level",
        "reciever",
        "token",
        "userId",
        "coin",
        "league",
      ]}
      nonRequiredParams={["updatedAt", "nftId", "nftOwner"]}
      isSign={true}
    >
      {({ queryParams, sign }) => {
        if (queryParams.updatedAt) {
          setUpdatedAt(+queryParams.updatedAt);
        }

        return (
          <>
            {isLoader && <Loader />}

            <div className={styles.capybaraContainer}>
              <div
                className={clsx(styles.capybaraCard, {
                  [styles.minted]: capybaraNftStatus === "UPDATABLE",
                  [styles.minting]: capybaraNftStatus === "MINTING",
                })}
              >
                <div className={styles.additionalDataText}>
                  {capybaraNftStatus === "MINTING" && (
                    <p>
                      Player Card
                      <br /> NFT
                    </p>
                  )}
                </div>
                <img className={styles.nftBackground} src={NftBorder} />
                <img
                  draggable="false"
                  className={clsx(styles.capybaraImg, {
                    [styles.mintingImg]: capybaraNftStatus === "MINTING",
                  })}
                  src={capybaraLevels[+queryParams.level].img}
                  alt="state"
                />

                <div
                  className={clsx(styles.capybaraAttributesWrapper, {
                    [styles.capybaraAttributesUpdateble]:
                      capybaraNftStatus === "UPDATABLE",
                    [styles.capybaraAttributesMintable]:
                      capybaraNftStatus === "MINTING",
                  })}
                >
                  <div className={styles.capybaraAttributesContainer}>
                    <p className={clsx(styles.capybaraAttributesTitle)}>
                      Attributes:
                    </p>
                    <div className={styles.capybaraAttributesBlock}>
                      <p>
                        Coins Earned:
                        <Coin width={12} height={12} /> {queryParams.coin}
                      </p>
                      <p> League: {queryParams.leagueName} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.feeContainer}>
                <div className={styles.feeInfo}>
                  <p>Transaction Fee</p>
                  <p className={styles.amount}>0.01 SUI</p>
                </div>
                <Info />
              </div>
              {capybaraNftStatus === "MINTING" && (
                <Button
                  onClick={() => {
                    sign &&
                      transfer({
                        sign,
                        reciever: queryParams.reciever,
                        token: queryParams.token,
                        userId: queryParams.userId,
                      });
                  }}
                  block={true}
                  label={"Mint Player Card NFT"}
                />
              )}

              {capybaraNftStatus === "UPDATABLE" && (
                <Button
                  onClick={() => {
                    sign &&
                      transfer({
                        sign,
                        reciever: queryParams.reciever,
                        nftId: queryParams.nftId,
                        token: queryParams.token,
                        userId: queryParams.userId,
                        isUpdate: true,
                      });
                  }}
                  block={true}
                  label={"Update Attributes"}
                />
              )}

              <Dialog
                modalWallpaper={""}
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(false);
                }}
                onAction={() => navigate("/wallet")}
                title={"Not enough SUI"}
                text={`You don’t have enough SUI in your wallet to cover the Transaction Fee.
           Please Buy SUI or deposit from external wallet.`}
                modalTitleColor={"#C0E6FF;"}
                modalIcon={<img width={80} height={80} src={SuiImg} />}
                buttonText={"Buy"}
                additionalbuttonText="Deposit"
                handleAdditionalAction={() => navigate("/wallet?receive=true")}
              />

              <Dialog
                modalWallpaper={""}
                isOpen={isError}
                onClose={handleClose}
                onAction={() => handleClose()}
                title={
                  queryParams.owner &&
                  queryParams.owner !== currentAccount?.address
                    ? "Invalid NFT Owner Address"
                    : "Something Went Wrong"
                }
                text={
                  queryParams.owner &&
                  queryParams.owner !== currentAccount?.address
                    ? "The provided NFT owner address does not match the current account. Please use a different account."
                    : "Please click the button below to try again."
                }
                modalTitleColor={"#C0C3FF"}
                modalIcon={<img width={80} height={80} src={SuiImg} />}
                buttonText={"Return to Capybara"}
              />
            </div>
          </>
        );
      }}
    </QueryWrapper>
  );
});
