import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import { menuItems } from "@/constants";
import { isTelegram } from "@/utils";
import { observer } from "mobx-react-lite";
import ToastContainer from "@/components/ToastContainer/ToastContainer";

const getBackgroundAndWallpaper = (path: string, level: number) => {
  const item = menuItems.find((item) => item.path === path);
  if (item) {
    return {
      backgroundColor: item.backgroundColor(level),
      wallpaper: item.wallpaper(level),
      headerColor: item.headerColor(level),
    };
  }
  return { backgroundColor: "", wallpaper: "", headerColor: "" };
};

const WalletAuthLayout: React.FC<{
  children: React.ReactNode;
}> = observer(({ children }) => {
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [wallpaper, setWallpaper] = useState<string>("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const level = queryParams.has("level")
      ? Number(queryParams.get("level"))
      : 1;
    const { backgroundColor, wallpaper, headerColor } =
      getBackgroundAndWallpaper("/", level);
    setBackgroundColor(backgroundColor);
    setWallpaper(wallpaper);
    isTelegram && Telegram.WebApp.setHeaderColor(headerColor);
  }, [location.pathname]);

  return (
    <div
      style={{
        background: backgroundColor,
      }}
      className={styles.layout}
    >
      <div
        className={`${styles.topWallpaper} ${styles.wallpaper}`}
        style={{
          backgroundImage: `url(${wallpaper})`,
        }}
      >
        {children}
        <ToastContainer />
      </div>
    </div>
  );
});

export default WalletAuthLayout;
