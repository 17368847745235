import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import classes from "./Story.module.sass";
import { storyManagerStore } from "@/store";
import { observer } from "mobx-react-lite";
import Stories from "react-insta-stories";

interface Story {
  id: string;
  mediaUrl: string;
  type: "image" | "video";
  duration: number;
  caption?: string;
}
let playTimer: NodeJS.Timeout;

const Story = observer(() => {
  const { activeStories } = storyManagerStore;
  const [isMounted, setIsMounted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (activeStories.length > 0) {
      setIsMounted(true);
      playTimer = setTimeout(() => {
        setIsPlaying(true);
      }, 800); // задержка для прокрутки анимации
    }
    return () => {
      setIsMounted(false);
      setIsPlaying(false);
      clearTimeout(playTimer);
    };
  }, [activeStories]);

  const className = clsx(classes.storyWrapper, {
    [classes.playing]: isPlaying,
    [classes.mounted]: isMounted,
  });

  return (
    activeStories.length > 0 && (
      <div className={className}>
        <motion.div
          className={classes.circle}
          initial={{
            width: "10px",
            height: "10px",
            top: "20px",
            left: "20px",
            zIndex: -1,
          }}
          animate={{
            width: "1500px",
            height: "1500px",
            top: "-500px",
            left: "-500px",
            border: "3px solid rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            opacity: 1,
          }}
          transition={{ duration: 1 }}
          style={{
            borderRadius: "50%",
            border: "3px solid rgba(255, 255, 255, 0.8)",
            position: "absolute",
          }}
        />
        {isPlaying && (
          <motion.div
            className={classes.story}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Stories
              storyContainerStyles={{
                background: "rgb(103 166 255)",
              }}
              onAllStoriesEnd={() => {
                storyManagerStore.clearActiveSet();
              }}
              stories={activeStories}
              defaultInterval={1500}
              width={"100%"}
              height={"100%"}
            />
          </motion.div>
        )}
      </div>
    )
  );
});

export default Story;
