import { EnokiFlowProvider } from "@mysten/enoki/react";

interface EnokiProviderProps {
  children: React.ReactNode;
}

export const EnokiProvider = ({ children }: EnokiProviderProps) => {
  return (
    <EnokiFlowProvider apiKey={"enoki_public_063a0a58b76cf45bdc97af84039c4d21"}>
      {children}
    </EnokiFlowProvider>
  );
};
