import {
  ContentResponse,
  FriendMessageV2,
  GameOptions,
  LeaguesMessage,
  MissionMessage,
  SyncResponse,
  TapResponse,
} from "./types";
import { isBackend, isProduction, isTelegram } from "@/utils";
import { gameStore, toastManagerStore } from "@/store";
import { GameState } from "@/store/GameStore";
import ky from "ky";
import { coinbase } from "@/constants";

export default abstract class Backend {
  public ready: Promise<void>;
  protected readyResolve: (() => void) | undefined;
  public options: GameOptions | undefined;
  public initState: SyncResponse | undefined;
  public tapCount: number = 0;
  public tapTimeout: undefined | ReturnType<typeof setTimeout>;
  public tapTimeoutTime: number = 0;
  public syncTimeout: undefined | ReturnType<typeof setTimeout>;
  public reconnects: number = 0;

  constructor() {
    this.ready = new Promise((r) => (this.readyResolve = r));
  }

  abstract init(): void;

  protected abstract send(
    message: object | null,
    schema: string,
    auth?: string | null
  ): Promise<ContentResponse>;

  public abstract getContext(provider: "google" | "trust_wallet"): Promise<void>;

  public abstract sendContext(
    hash: string,
    token: string,
    provider: "google" | "trust_wallet"
  ): void;

  public abstract sendWallet(hash: string, walletType: "STASHED" | "OKX"): void;

  public abstract logoutWallet(): void;

  protected updateSyncTimeout() {
    clearTimeout(this.syncTimeout);
    this.syncTimeout = setTimeout(() => {
      this.sendTaps();
    }, 80_000);
  }

  public get timestamp() {
    return gameStore.now / 1000;
  }

  public abstract resync(): Promise<SyncResponse>;

  public async getSuiCost(): Promise<{
    data: { rates: { USD: number } };
  }> {
    const result = (await ky
      .get(coinbase + "exchange-rates?currency=SUI")
      .json()) as { data: { rates: { USD: number } } };

    return result;
  }

  public async sendTaps() {
    const taps = this.tapCount;
    this.tapCount = 0;
    if (isTelegram && Telegram.WebApp.isClosingConfirmationEnabled) {
      Telegram.WebApp.disableClosingConfirmation();
    }
    const message = {
      action: "tap",
      countOfTaps: taps,
      timestamp: this.timestamp,
    };

    this.updateSyncTimeout();
    const state = Object.assign({}, gameStore.state);
    const result = (await this.send(message, "TapMessage")) as TapResponse;
    if (result.earnedReferralBalance > 0) {
      toastManagerStore.addToast(
        `New referral income: ${result.earnedReferralBalance}`
      );
      gameStore.addBalance(result.earnedReferralBalance);
    }
    this.checkProgress(state, result as TapResponse);
    return result;
  }

  public tap() {
    this.tapCount++;
    if (this.tapCount > 500) {
      this.sendTaps();
      clearTimeout(this.tapTimeout);
      return;
    }
    if (isTelegram && !Telegram.WebApp.isClosingConfirmationEnabled) {
      Telegram.WebApp.enableClosingConfirmation();
    }
    if (Date.now() > this.tapTimeoutTime + 500 || !this.tapTimeoutTime) {
      clearTimeout(this.tapTimeout);
      this.tapTimeout = setTimeout(() => this.sendTaps(), 2000);
      this.tapTimeoutTime = Date.now();
    }
  }

  public checkProgress(client: GameState, server: TapResponse): void {
    //return;
    if (!isBackend) return;
    if (isProduction) return;
    if (server.totalBalance < client.totalBalance) {
      throw new Error(
        `STATECHECK2: totalBalance s:${server.totalBalance} c:${client.totalBalance}`
      );
    }
    if (server.balance < client.balance) {
      throw new Error(
        `STATECHECK2: balance s:${server.balance} c:${client.balance}`
      );
    }
    if (server.energy < client.energy) {
      throw new Error(
        `STATECHECK2: energy s:${server.energy} c:${client.energy}`
      );
    }
  }

  public async sendSyncV2() {
    this.updateSyncTimeout();
    const initState = await this.send(
      { action: "sync_v2", timestamp: Date.now() / 1000 },
      "SyncMessageV2"
    );
    return initState as SyncResponse;
  }

  public async sendSyncV3() {
    this.updateSyncTimeout();
    const initState = await this.send(
      { action: "sync_v5", timestamp: Date.now() / 1000 },
      "SyncMessageV3"
    );
    return initState as SyncResponse;
  }

  public async sendBoost(boostId: number, level: number) {
    const message = {
      boostId,
      level,
      action: "boost",
      boostedAt: this.timestamp,
    };
    this.sendTaps();
    const result = await this.send(message, "BoostMessage");
    return result;
  }

  public async sendMission(missionId: number) {
    const message: MissionMessage = {
      missionId,
      action: "mission",
    };
    this.sendTaps();
    const result = await this.send(message, "MissionMessage");
    return result;
  }

  public async sendFriendsV2({
    limit,
    offset,
  }: {
    limit: number;
    offset: number;
  }) {
    const message: FriendMessageV2 = {
      action: "friend_v2",
      limit,
      offset,
    };

    const result = await this.send(message, "FriendMessageV2");
    return result;
  }

  // public async sendFriends() {
  //   const message: FriendMessage = {
  //     action: "friend",
  //   };
  //   const result = await this.send(message, "FriendMessage");
  //   return result;
  // }

  public async sendLeagues(body: Omit<LeaguesMessage, "action">) {
    const message: LeaguesMessage = {
      action: "league",
      ...body,
    };

    const result = await this.send(message, "LeagueRatingRequest");
    return result;
  }

  public get queueSize() {
    return 0;
  }
}
