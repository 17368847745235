//import { isProduction } from "@/utils";
import { gameStore } from "@/store";
import { observer } from "mobx-react-lite";
import classes from "./DevOverlay.module.sass";
import { backend } from "@/services";

const sec = 1000;
const min = sec * 60;
const hour = min * 60;
const day = hour * 24;

function time() {
  let t = gameStore.sessionTime;
  const days = Math.trunc(t / day);
  t = t % day;
  const hours = Math.trunc(t / hour);
  t = t % hour;
  //const mins = Math.trunc(t / min);
  //t = t % min;
  let result = "";
  if (days) result += ` ${days}d`;
  result += ` ${String(hours).padStart(2, "0")}h`;
  //result += ` ${mins}m`
  return result;
}

const Loader = observer(() => {
  return (
    <div className={classes.overlay}>
      <div>time: {time()}</div>
      
      {backend.reconnects && <div>Reconnects: {backend.reconnects}</div>}
      {backend.queueSize && <div>queue: {backend.queueSize}</div>}
    </div>
  );
});

export default Loader;
