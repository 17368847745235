import { FC } from "react";
import { observer } from "mobx-react-lite";

import capyBag from "@/assets/capybara-images/capybag-shine.png";
import { Button } from "@/components/Button";

import styles from './styles.module.sass';
import { DialogComponents } from "@/components/Dialog";

type MoneyBagTipProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAction: VoidFunction;
};

export const MoneyBagTip: FC<MoneyBagTipProps> = observer(({
  isOpen,
  onClose,
  onAction,
}) => {
  return (
    <DialogComponents.Container
      overlayClassName={styles.modalOverlay}
      wrapperClassName={styles.modalContentWrapper}
      isOpen={Boolean(isOpen)}
      onAction={onAction}
      onClose={onClose}
    >
      {({ handleClose }) => (
        <DialogComponents.Content className={styles.modalContent}>
          <DialogComponents.Closer
            className={styles.modalCloser}
            onClick={handleClose}
          ></DialogComponents.Closer>
          <div className={styles.capybag}>
            <img alt="capybag" src={capyBag} style={{maxWidth: "183px"}}/>
          </div>
          <DialogComponents.TextContainer className={styles.textContainer}>
            <DialogComponents.Title className={styles.modalTitle}>
              {`Unlock the mystery!`}
            </DialogComponents.Title>
            <DialogComponents.Text className={styles.modalText}>
              <span style={{lineHeight: '20px'}}>
                {`Opening the Money Bag reveals a surprise reward! `}
                <span style={{fontWeight: 700}}>You can multiply Money Bag price up to 3x</span>
                {
                  ` or receive slightly less than the original price. Ready to discover the treasure waiting for you? 💰✨\n
                  You can also list your Money Bag NFT for sale on a NFT marketplace!`
                }
              </span>
            </DialogComponents.Text>
          </DialogComponents.TextContainer>
          <div style={{ width: '100%' }}>
            <Button
              onClick={handleClose}
              label="Got it!"
              styleType="primary"
              block={true}
            ></Button>
          </div>
        </DialogComponents.Content>
      )}
    </DialogComponents.Container>
  )
});