import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";

export interface Story {
  id: string;
  url: string;
  type: "image" | "video";
  duration: number; 
  caption?: string;
}

export interface StorySet {
  id: string;
  stories: Story[];
}

export default class StoryManagerStore {
  rootStore: RootStore;
  storySets: StorySet[] = [];
  activeSetId: string | null = null;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  addStorySet(storySet: StorySet) {
    //console.log(storySet);
    this.storySets.push(storySet);
  }

  removeStorySet(id: string) {
    this.storySets = this.storySets.filter((set) => set.id !== id);
  }

  clearStorySets() {
    this.storySets = [];
  }

  showStorySet(id: string) {
    const storySet = this.storySets.find((set) => set.id === id);
    if (storySet) {
      this.activeSetId = id;
    }
  }

  get activeStories() {
    if (this.activeSetId) {
      const activeSet = this.storySets.find(
        (set) => set.id === this.activeSetId
      );
      return activeSet ? activeSet.stories : [];
    }
    return [];
  }

  clearActiveSet() {
    this.activeSetId = null;
  }
}
