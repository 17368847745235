import { useEffect } from "react";
import styles from "./styles.module.css";
import { useEnokiFlow } from "@mysten/enoki/react";
import { observer } from "mobx-react-lite";

export const AuthWalletsFlow = observer(() => {
  const enokiFlow = useEnokiFlow();

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    const protocol = window.location.protocol;
    const host = window.location.host;
    const query = new URLSearchParams(window.location.search);

    const redirectUrl = `${protocol}//${host}/auth`;
    enokiFlow
      .createAuthorizationURL({
        provider: "google",
        network: "mainnet",
        clientId:
          "105071650217-65a0r17esu89sp49j0qp0ad8u1797hls.apps.googleusercontent.com",
        redirectUrl,
        extraParams: {
          scope: ["openid", "email", "profile"],
          prompt: "select_account",
          state: JSON.stringify({
            isOkx: query.get("isOkx"),
            recieverAddress: query.get("receiverAddress") ?? "",
            token: query.get("token"),
          }),
        },
      })
      .then((url: string) => {
        window.location.href = url;
      })
      .catch((error: unknown) => {
        console.error(error);
      });
  }, []);

  return <div className={styles.walletWrapper}></div>;
});
