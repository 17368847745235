import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import { useCurrentAccount } from "@mysten/dapp-kit";
import { useEffect, useState } from "react";

const WalletBuy = observer(() => {
  const params = {
    apiKey: "pk_prod_01GRKP77YFPR59QEGFM2C0746X",
    mode: "buy",
  };

  const [generatedUrl, setGeneratedUrl] = useState("");
  const currentAccount = useCurrentAccount();

  useEffect(() => {
    if (currentAccount?.address) {
      const updatedParams = {
        ...params,
      };
      const urlParams = new URLSearchParams(updatedParams).toString();
      const url = `https://buy.onramper.com?${urlParams}`;
      setGeneratedUrl(url);
    }
  }, [currentAccount]);

  return (
    <div className={styles.content}>
      <iframe
        src={generatedUrl}
        title="Onramper Widget"
        height="100%"
        width="100%"
        allow="accelerometer; autoplay; camera; gyroscope; payment; microphone"
      />
    </div>
  );
});

export default WalletBuy;
