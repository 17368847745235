import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@/components/Button";

import styles from './styles.module.sass';
import { DialogComponents } from "@/components/Dialog";

type TransactionFeeProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onAction: VoidFunction;
}

export const TransactionFee: FC<TransactionFeeProps> = observer(({
  isOpen,
  onClose,
  onAction,
}) => {
  return (
    <DialogComponents.Container
      overlayClassName={styles.modalOverlay}
      wrapperClassName={styles.modalContentWrapper}
      isOpen={Boolean(isOpen)}
      onAction={onAction}
      onClose={onClose}
    >
      {({ handleClose }) => (
        <DialogComponents.Content className={styles.modalContent}>
          <DialogComponents.Closer
            className={styles.modalCloser}
            onClick={handleClose}
          ></DialogComponents.Closer>
          <DialogComponents.TextContainer className={styles.textContainer}>
            <DialogComponents.Title className={styles.modalTitle}>
              {`Transaction Fee`}
            </DialogComponents.Title>
            <DialogComponents.Text className={styles.modalText}>
              {
                `Every transaction on the Sui blockchain requires a small fee to ensure fast, secure, and reliable processing. This fee helps maintain the network’s efficiency and scalability, enabling smooth gameplay.

                Note: This fee does not go to Capybara directly, but supports the overall Sui blockchain infrastructure.`
              }
            </DialogComponents.Text>
          </DialogComponents.TextContainer>
          <Button
            onClick={handleClose}
            label="Got it!"
            styleType="primary"
            block={true}
          ></Button>
        </DialogComponents.Content>
      )} 
    </DialogComponents.Container>
  )
});