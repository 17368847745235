import { observer } from "mobx-react-lite";
import styles from "./styles.module.css";

interface ProgressBarProps {
  img: string;
  level: number;
  leagueName: string;
  selectedLeague: number;
  onClick: () => void;
}

export const LeagueFace = observer(
  ({ img, leagueName, selectedLeague, level, onClick }: ProgressBarProps) => {
    return (
      <div id={leagueName} className={styles.leagueFaceContainer} onClick={onClick}>
        <div
          className={`${styles.leagueFace} ${
            level === selectedLeague ? styles.selectedLeague : ""
          }`}
        >
          <img src={img} alt={leagueName} />
        </div>
        <p className={styles.leagueName}>{leagueName}</p>
      </div>
    );
  }
);
