import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import clsx from "clsx";

import Coin from "@/assets/capybara-images/coin.svg?react";
import sui from "@/assets/capybara-images/sui.png";
import Info from "@/assets/icons/info.svg?react";
import { Badge } from "@/components/Badge";
import capyBag from "@/assets/capybara-images/capybag.png";
import Star1 from "@/assets/icons/star1.svg?react";

import { Icon } from "@/components/Icon";
import { TFruit } from "../Collections";

import styles from '../styles.module.sass';

type OffersProps = {
  fruits: TFruit[];
  allCollected: boolean;
  onMintForClick?: VoidFunction;
  onBuyForClick?: VoidFunction,
  onTipClick?: VoidFunction
  onMintNftClick?: VoidFunction;
}

const OfferBag: FC<{ onMintForClick?: VoidFunction }> = observer(({
  onMintForClick,
}) => (
  <div className={clsx(styles.slide, styles.slide_bg, styles.slide__bag)}>
    <div className={styles.slide__content}>
      <span className={styles.slide_title}>
        {`You Can Mint\na Money Bag!`}
      </span>
      <Badge size="s" color="blue" className={styles.slide__badge} width="100%" onClick={onMintForClick}>
        mint for
        <Coin width="20" height="20"/>
      </Badge>
    </div>
    <div style={{ background: `url(${capyBag}) no-repeat center`, width: '100%', maxWidth: '116px', backgroundSize: 'cover', position: 'relative' }}>
      <Star1 className={styles.star}/>
    </div>
  </div>
));

const OfferCollect: FC<OffersProps> = observer(({
  fruits,
  allCollected,
  onBuyForClick,
  onTipClick,
  onMintNftClick,
}) => (
  <div className={clsx(styles.slide, styles.slide_bg, styles.slide__collect)}>
    <div className={styles.slide__content}>
      <span className={styles.slide_title} onClick={onTipClick}>
        {`Collect 5 fruits to mint\na Capybara NFT`}
        <Info style={{ margin: '-2px 5px'}}/>
      </span>
      <div className={styles.slide__fruits}>
        {
          fruits.map((fruit) => {
            return <Icon size="30px" content={<>{fruit.icon}</>} className={clsx(styles.slide__icon, {
              [styles.icon_disabled]: !fruit.collected,
            })}/>
          })
        }
      </div>
      {
        !allCollected && (
          <Badge size="s" color="blue" className={styles.slide__badge} width="75%" onClick={onBuyForClick}>
            Buy for
            <i style={{ background: `url(${sui}) no-repeat center`, width: '30px', height: '30px'}} />
          </Badge>)
      }
      {
        allCollected && (
          <Badge size="s" color="blue" className={styles.slide__badge} width="75%" onClick={onMintNftClick}>
            Mint NFT
          </Badge>)
      }
    </div>
  </div>
));

export const Offers: FC<OffersProps> = observer(({
  fruits,
  allCollected,
  onMintForClick,
  onBuyForClick,
  onTipClick,
  onMintNftClick
}) => {
  return (
    <div className={styles.offers}>
      <Swiper
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        <SwiperSlide>
          <OfferBag onMintForClick={onMintForClick}/>
        </SwiperSlide>
        <SwiperSlide>
          <OfferCollect fruits={fruits} allCollected={allCollected} onBuyForClick={onBuyForClick} onTipClick={onTipClick} onMintNftClick={onMintNftClick} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
});
