import { OKXUniversalConnectUI, THEME } from "@okxconnect/ui";

export const connectWallet = () => {
  return OKXUniversalConnectUI.init({
    dappMetaData: {
      icon: "https://static.okx.com/cdn/assets/imgs/247/58E63FEA47A2B7D7.png",
      name: "Capybara",
    },
    actionsConfiguration: {
      returnStrategy: "tg://resolve",
      modals: "all",
    },
    language: "en_US",
    uiPreferences: {
      theme: THEME.LIGHT,
    },
  });
};
