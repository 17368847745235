import styles from "./styles.module.css";
import { formatNumber } from "@/utils/format";
import { gameStore, leagueStore } from "@/store";

export const LeagueProgressBar = () => {
  const { next } = leagueStore;
  const { balance } = gameStore;

  const progress = Math.min((balance / (next?.threshold ?? 0)) * 100, 100);

  return (
    <div className={styles.progressBarWrapper}>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressbalance}>
          {balance.toLocaleString("en-US")} of{" "}
          {next ? formatNumber(next?.threshold ?? 0) : "max level"}
        </div>
        <div className={styles.progressWrapper}>
          <div className={styles.progressContainer}>
            <div
              className={styles.progressBar}
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
