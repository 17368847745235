import { action, observable } from "mobx";
import type RootStore from "./RootStore";

interface Toast {
  id: number;
  message: string;
  isBlack?: boolean;
  icon?: string;
  duration?: number;
}

export default class ToastManagerStore {
  rootStore: RootStore;
  @observable public accessor toasts: Toast[] = [];
  private nextId: number = 1;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action.bound addToast(
    message: string,
    icon?: string,
    isBlack?: boolean,
    duration: number = 3000
  ) {
    const existingToast = this.toasts.find(
      (toast) => toast.message === message && toast.icon === icon
    );
    if (existingToast) {
      return;
    }

    const id = this.nextId++;
    this.toasts.push({ id, message, icon, duration, isBlack });

    setTimeout(() => {
      this.removeToast(id);
    }, duration);
  }

  @action.bound removeToast(id: number) {
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
  }
}
