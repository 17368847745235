import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "@/components/Button";

import styles from './styles.module.sass';
import { Icon } from "@/components/Icon";
import { DialogComponents } from "@/components/Dialog";
import { TFruit } from "../../Collections";

type CollectTipProps = {
  fruits: TFruit[];
  isOpen: boolean;
  onClose: VoidFunction;
}

export const CollectTip: FC<CollectTipProps>= observer(({
  fruits,
  isOpen,
  onClose
}) => {
  return (
    <DialogComponents.Container
      overlayClassName={styles.modalOverlay}
      wrapperClassName={styles.modalContentWrapper}
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      onAction={onClose}
    >
      {({ handleClose }) =>(
        <DialogComponents.Content className={styles.modalContent}>
          <DialogComponents.Closer
            className={styles.modalCloser}
            onClick={handleClose}
          />
          <div className={styles.fruits}>
            {
              fruits.map(fruit => <Icon size="30px" content={<>{fruit.icon}</>} />)
            }
          </div>
          <DialogComponents.TextContainer className={styles.textContainer}>
            <DialogComponents.Title className={styles.modalTitle}>
              {`Collect all 5 different\nFood NFTs`}
            </DialogComponents.Title>
            <DialogComponents.Text className={styles.modalText}>
              {
                `to mint an exclusive Capybara NFT and receive a special bonus!
                You can get your Food NFTs by opening Money Bags or purchasing them. 
                You can also list your NFTs for sale on the marketplace!
                Start your collection now and enjoy the rewards!`
              }
            </DialogComponents.Text>
          </DialogComponents.TextContainer>
          <Button
            onClick={handleClose}
            label="Got it!"
            styleType="primary"
            block={true}
          ></Button>
        </DialogComponents.Content>
      )}
    </DialogComponents.Container>
  )
});