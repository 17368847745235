import clsx from "clsx";
import { observer } from "mobx-react-lite";

import styles from "./styles.module.sass";
import { FC } from "react";

type BagProps = {
  onClick: VoidFunction;
}

export const Bag: FC<BagProps> = observer(({
  onClick,
}) => {
  return (
    <button className={styles.container} onClick={onClick}>
      <div className={clsx(styles.bag)}></div>
    </button>
  )
});
