import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import coinImg from "@/assets/icons/coin.svg";

import { missionStore } from "@/store";
import { formatNumber, isProduction } from "@/utils";
import { cdnProduction, cdnStage } from "@/constants";

import styles from "./Explore.module.sass";
import { TaskItem } from "../TaskItem";
import { SuccessToast } from "@/store/Mission";

export const Explore: FC = observer(() => {
  const { id } = useParams();

  const handleCheck = (id: number) => {
    const item = missionStore.getById(id);

    const toastData: SuccessToast = {
      text: `+${formatNumber(item?.reward ?? 0)}`,
      icon: coinImg,
    };

    if (item) {
      if (item?.action === "LINK" || item?.action === "CHANNEL") {
        console.warn("item?.action go", item?.action);
        item.go(true, toastData);
      } else {
        console.warn("Else condition", item?.action);
        item.isDone === false && item.check(true, toastData);
      }
    }
  };

  const tasksList = useMemo(() => id
    ? (missionStore.all.filter(({ campaignId }) => campaignId === Number(id)) || [])
    : [],
    [id]
  );

  const campaign = id ? missionStore.getCampaignById(Number(id)) : null;
  const campaignBanner = `${isProduction ? cdnProduction : cdnStage}/${campaign?.banner || ''}`;

  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#1a64ce");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  return (
    <div className={styles.explore} style={{ background: `url(${campaignBanner})` }}>
      <div className={styles.header}></div>
      <div className={styles.content}>
        <h2>{ campaign?.nameEn } Tasks</h2>
        <div className={styles.tasks}>
          {
            tasksList.map((item) => (
              <TaskItem
                key={item.id}
                onClick={() => handleCheck(item.id)}
                id={item.id}
                taskIcon={item.icon}
                title={item.name}
                reward={item.reward}
                isCollected={item.isDone}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
});