import React from "react";

export const renderTextWithLineBreaks = (text: string) => {
  return text.split("/n").map((line, index, array) => (
    <React.Fragment key={line}>
      {line}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
};
