import React, { useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";
import ModalCloser from "@/assets/capybara-images/modalCloser.svg?react";

import styles from "../Dialog.module.sass";

type DialogComponent = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

type TDialogComponents = {
  Container: React.FC<{
    isOpen: boolean;
    children: (props: { handleClose: VoidFunction, handleAction: VoidFunction, closing: boolean, setClosing: React.Dispatch<React.SetStateAction<boolean>>}) => React.ReactNode;
    onClose?: VoidFunction;
    onAction?: VoidFunction;
    props?: ReactModal.Props;
    wrapperClassName?: string,
    overlayClassName?: string,
  } & Omit<DialogComponent, 'children'>>;
  Content: React.FC<DialogComponent>;
  Closer: React.FC<DialogComponent & { onClick: VoidFunction }>;
  Image: React.FC<DialogComponent & { src: string, alt: string }>;
  TextContainer: React.FC<DialogComponent>;
  Title: React.FC<DialogComponent>;
  Text: React.FC<DialogComponent>;
}

export const DialogComponents: TDialogComponents = {
  Container: observer(({
    onAction,
    onClose,
    isOpen,
    props,
    children,
    className = "",
    wrapperClassName = "",
    overlayClassName = "",
  }) => {
    const [closing, setClosing] = useState(false);

    const handleClose = () => {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
        onClose && onClose();
      }, 300);
    };

    const handleAction = () => {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
        onAction && onAction();
      }, 300);
    };

    return (
      <ReactModal
        ariaHideApp={false}
        overlayClassName={clsx(styles.modalOverlay, overlayClassName)}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleClose}
        className={clsx(styles.modalContainer, className, {[styles.modalClose]: closing})}
        isOpen={isOpen}
        {...props}
      >
        <div className={clsx(styles.modalContentWrapper, wrapperClassName)}>
          {children && children({ handleClose, handleAction, closing, setClosing })}
        </div>
      </ReactModal>
    );
  }),
  Content: observer(({ style = {}, children, className = "" }) => (
    <div
      className={clsx(styles.modalContent, className)}
      style={style}
    >
      { children }
    </div>
  )),
  Closer: observer(({ onClick, className = "" }) => <ModalCloser className={clsx(styles.modalCloser, className)} onClick={onClick} />),
  Image: observer(({ src = "", className = "", alt, style = {} }) => <img className={clsx(styles.modalImg, className)} src={src} alt={alt} style={style}/>),
  TextContainer: observer(({ children, className = "" }) => <div className={clsx(styles.textContainer, className)}>{children}</div>),
  Title: observer(({ children, className = "", style = {} }) => (
    <h2
      style={style}
      className={clsx(styles.modalTitle, className)}
    >
      {children}
    </h2>
  )),
  Text: observer(({ children, className = "" }) => <p className={clsx(styles.modalText, className)}>{children}</p>),
}
