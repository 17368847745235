import { FC } from "react";
import { observer } from "mobx-react-lite";

import styles from "./BoostersWrapper.module.sass";

type BoostersWrapperProps = {
  children: React.ReactNode;
	title: string;
	className: string;
}

export const BoostersWrapper: FC<BoostersWrapperProps> = observer(({ children, title, className }) => {
  return <div className={className}>
    <p className={styles.tasksTitle}>{ title }</p>
		<div className={styles.boostersWrapper}>
			{ children }
		</div>
  </div>
});
