import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const backButtonHandleClick = (navigate: NavigateFunction) => {
  navigate(-1);
};

let lastCallback = () => {};

const TelegramHeaderManager = observer(() => {
  const navigate = useNavigate();

  function callback() {
    backButtonHandleClick(navigate);
  }

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath !== "/") {
      if (!Telegram.WebApp.BackButton.isVisible) {
        Telegram.WebApp.BackButton.show();
        Telegram.WebApp.BackButton.offClick(lastCallback);
        lastCallback = callback;
        Telegram.WebApp.BackButton.onClick(lastCallback);
      }
    } else {
      if (Telegram.WebApp.BackButton.isVisible) {
        Telegram.WebApp.BackButton.hide();
        Telegram.WebApp.BackButton.offClick(lastCallback);
      }
    }

    return () => {};
  }, [location.pathname]); // eslint-disable-line

  return null;
});

export default TelegramHeaderManager;
