import React from "react";
import styles from "./styles.module.css";
import { renderTextWithLineBreaks } from "@/utils/renderTextWithLineBreaks";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

interface BoostProps {
  title: string;
  isMaxLevel: boolean;
  onClick?: () => void;
  hint?: string | JSX.Element;
  children?: React.ReactNode;
  className?: string;
}

export const BoostItem: React.FC<BoostProps> = 
  observer(({
    title,
    onClick,
    hint = '',
    isMaxLevel,
    className,
    children,
  }) => {
    return (
      <div
        className={clsx(styles.boostItemBlock, {
          [styles.isMaxed]: isMaxLevel,
          ...(className ? { [className]: Boolean(className) } : {})
        })}
        onClick={onClick}
      >
        <div className={styles.boostBlockInfoContainer}>
          <p className={styles.title}>{renderTextWithLineBreaks(title)}</p>
          { hint && <div className={styles.hint}>{hint}</div>}
        </div>
        <div className={styles.boostBlockCostContainer}>
          { children }
        </div>
      </div>
    );
  });
