import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import WalletCopy from "@/assets/capybara-images/walletCopy.svg?react";
import LogoutIcon from "@/assets/icons/logout.svg?react";
import { toastManagerStore, walletStore } from "@/store";
import { isProduction, shortenText } from "@/utils";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { WalletDialog } from "@/components/Wallet/WalletDialog/WalletDialog";
import QRCode from "qrcode";
import SuiImg from "@/assets/capybara-images/sui.png";
import TokenComponent from "./TokenCoponent";
import clsx from "clsx";

const WalletPage = observer(() => {
  const navigate = useNavigate();
  const address = walletStore.address;
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [isOpenReceive, setIsOpenReceive] = useState(false);

  useEffect(() => {
    if (address) {
      QRCode.toDataURL(address, {
        color: {
          dark: "#000",
          light: "#f5f7f9",
        },
      }).then((url: string) => {
        if (window.location.href.includes("receive=true")) {
          setIsOpenReceive(true);
        }
        setQrCode(url);
      });
    }
  }, [address]);

  return (
    address && (
      <>
        <div>
          <div className={styles.walletTitleBlock}>
            <span className={styles.walletRound} />
            <p className={styles.walletTitle}>Wallet</p>
            <span className={styles.vector}>{">"}</span>
            <p className={styles.walletAddress}>
              {shortenText(address ?? "undefined", 8)}
            </p>
            <div
              onClick={() => {
                walletStore.logout();
                navigate("/", { replace: true });
              }}
              className={clsx(styles.logoutButton, styles.actionButton)}
            >
              Log out <LogoutIcon />
            </div>
          </div>
          <div
            className={clsx(
              styles.walletFinanceBlock,
              styles.walletFinanceBlock_background
            )}
          >
            <div className={styles.money}>
              {!walletStore.usdBalance
                ? "0 $"
                : ("$" + walletStore.usdBalance).substring(
                    0,
                    walletStore.usdBalance.toString().indexOf(".") + 4
                  )}
            </div>
            <div
              onClick={() =>
                navigator.clipboard
                  .writeText(address ?? "")
                  .then(() =>
                    toastManagerStore.addToast(
                      "Address copied to clipboard",
                      undefined,
                      true
                    )
                  )
              }
              className={styles.walletAddressCopy}
            >
              <p>{shortenText(address ?? "undefined", 8)}</p>
              <WalletCopy />
            </div>
            <div className={styles.buttonsContainer}>
              <div
                onClick={() => setIsOpenReceive(true)}
                className={styles.actionButton}
              >
                <p>receive</p>
              </div>
              <div
                onClick={() => navigate("/WalletTransfer", { replace: true })}
                className={styles.actionButton}
              >
                <p>send</p>
              </div>
              {!isProduction && (
                <div
                  onClick={() => navigate("/WalletBuy")}
                  className={styles.actionButton}
                >
                  <p>buy</p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.walletFinanceBlock}>
            <p className={styles.title}>Tokens</p>
            <TokenComponent imgLink={SuiImg} currency="SUI" />
          </div>
        </div>
        <WalletDialog
          isOpen={isOpenReceive}
          content={
            <div className={styles.walletQrWrapper}>
              <img src={qrCode ?? ""} className={styles.qr} />
              <div className={styles.walletTitleBlock}>
                <span className={styles.walletRound} />
                <p className={styles.walletTitle}>Wallet</p>
              </div>
              <div
                onClick={() =>
                  navigator.clipboard
                    .writeText(address ?? "")
                    .then(() =>
                      toastManagerStore.addToast(
                        "Address copied to clipboard",
                        undefined,
                        true
                      )
                    )
                }
                className={styles.walletAddressCopy}
              >
                <p>{address}</p>
                <WalletCopy />
              </div>
            </div>
          }
          onClose={() => setIsOpenReceive(false)}
          onAction={() => {
            Telegram.WebApp.openTelegramLink(
              `https://t.me/share/url?url=${address}`
            );
            setIsOpenReceive(false);
          }}
          headerTitle={"Receive"}
          buttonText={"Share"}
        />
      </>
    )
  );
});

export default WalletPage;
