import { FC } from "react";
import { observer } from "mobx-react-lite";

import fruitsBg from "@/assets/capybara-images/fruitsNftBg.png";
import Info from "@/assets/icons/info.svg?react";

import { Button } from "@/components/Button";
import { DialogComponents } from "@/components/Dialog";
import { TransactionFee } from "@/components/TransactionFee";

import styles from './styles.module.sass';

type MintTipProps = {
  isOpen: boolean;
  isFeeTip: boolean;
  onAction: VoidFunction;
  onClose: VoidFunction;
  onFeeTip: VoidFunction;
  closeFeeTip: VoidFunction;
}

export const MintTip: FC<MintTipProps> = observer(({
  isOpen,
  isFeeTip,
  onAction,
  onClose,
  onFeeTip,
  closeFeeTip,
}) => {
  return (
    <>
      <DialogComponents.Container
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
        isOpen={Boolean(isOpen)}
        onAction={onAction}
        onClose={onClose}
      >
        {({ handleClose }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              className={styles.modalCloser}
              onClick={handleClose}
            />
            <DialogComponents.Image src={fruitsBg} alt="fruits"/>
            <DialogComponents.TextContainer>
              <DialogComponents.Title>
                {`Mint Capybara NFT`}
              </DialogComponents.Title>
            </DialogComponents.TextContainer>
            <div className={styles.fee} onClick={onFeeTip}>
              <span className={styles.fee__name}>Mint fee</span>
              <span className={styles.fee__amount}>
                ~0.002 SUI <Info width={18} height={18} style={{marginLeft: '10px'}}/>
              </span>
            </div>
            <Button
              onClick={onAction}
              label="Mint NFT"
              styleType="primary"
              block={true}
            ></Button>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
      <TransactionFee isOpen={isFeeTip} onClose={closeFeeTip} onAction={closeFeeTip}/>
    </>

  )
});