import { action, computed, observable, reaction } from "mobx";
import type RootStore from "./RootStore";
import { BoostOption } from "@/services/types";
import { gameStore } from "@/store";
import { backend } from "@/services";

interface State {
  attempts: number;
  lastUseTimestamp: number | null;
}

interface Options {
  id: number;
  name: string;
  description: string;
  attempts: number;
  duration: number;
}

export default class BoostTapStore {
  rootStore: RootStore;
  @observable.ref public accessor options: Options | undefined;
  @observable private accessor state: State | null = null;
  @observable public accessor isRocket: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action init(bo: BoostOption | undefined) {
    if (!bo) {
      throw new Error("Empty options for Turbo boost");
    }
    if (bo.attempts) {
      this.options = {
        id: bo.externalId,
        name: bo.nameEn,
        description: bo.descriptionEn,
        attempts: bo.attempts,
        duration: 30,
      };
    } else {
      throw new Error("incorrect tap boost options");
    }
  }

  @action initState(state: State) {
    if (state) {
      this.state = {
        attempts: state.attempts,
        lastUseTimestamp: null,
      };
      if (state.lastUseTimestamp) {
        this.state.lastUseTimestamp = Math.trunc(state.lastUseTimestamp * 1000);
      }
    }
    reaction(
      () => gameStore.midnight,
      () => {
        if (this.state && this.options?.attempts) {
          this.state.attempts = this.options.attempts;
        }
      }
    );
  }

  @computed public get name() {
    return this.options!.name;
  }

  @computed public get dailyAttempts() {
    return this.options!.attempts;
  }

  @computed public get desc() {
    return this.options!.description;
  }

  @computed public get id() {
    return this.options!.id;
  }

  @computed public get duration() {
    if (this.options?.duration) {
      return this.options.duration * 1000;
    } else {
      return 0;
    }
  }

  @computed public get lastUse() {
    if (this.state?.lastUseTimestamp) {
      return this.state?.lastUseTimestamp;
    } else {
      return 0;
    }
  }

  @computed public get attempts() {
    if (this.state && this.state.attempts > 0) {
      return this.state.attempts;
    } else {
      return 0;
    }
  }

  @computed public get eta() {
    if (this.attempts) {
      const delta = gameStore.now - this.lastUse;
      let result = this.duration - delta;
      if (result < 0) result = 0;
      return result;
    } else if (this.attempts === 0) {
      return gameStore.nextMidnightTimestamp - gameStore.now;
    } else {
      return -1;
    }
  }

  @computed public get canUse() {
    return this.eta === 0;
  }

  @computed public get activeEta() {
    if (this.lastUse) {
      const delta = gameStore.now - this.lastUse;
      if (delta < this.duration) {
        return this.duration - delta;
      }
    }
    return 0;
  }

  @computed public get isActive() {
    if (this.activeEta) {
      return true;
    } else {
      return false;
    }
  }

  @action.bound use() {
    if (this.canUse && this.state) {
      this.isRocket = false;
      backend.sendBoost(this.id, 1);
      this.state.lastUseTimestamp = gameStore.now;
      this.state.attempts--;
    }
  }

  @action.bound startRocket() {
    this.isRocket = true;
  }
}
