import React from "react";
import styles from "./styles.module.css";
import refLvlImg from "@/assets/capybara-images/lvlImg.png";
import { ReferralLevelItem } from "../ReferralLevelItem";

const refLevels = [
  {
    img: refLvlImg,
    hint: "You’ll get 16% of all coins earned by your friends from feeding their Capybaras",
    reward: 16,
  },
  {
    img: refLvlImg,
    hint: "You’ll get 8% of coins earned by/n the referrals of your friends",
    reward: 8,
  },
];

export const ReferralLevelList: React.FC = () => {
  return (
    <div className={styles.referralLevelContainer}>
      {refLevels.map((item, index) => (
        <ReferralLevelItem
          key={item.hint}
          img={item.img}
          hint={item.hint}
          index={index}
          reward={item.reward}
        />
      ))}
    </div>
  );
};
