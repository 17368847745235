import { observer } from "mobx-react-lite";
import { Icon } from "@/components/Icon";

import styles from '../styles.module.sass';

export const Header = observer(() => (
  <div className={styles.header}>
    <Icon rotate="30" size="35px" content={<>🍑</>} className={styles.header__icon} />
    <h2 className={styles.title}>NFT collection</h2>
  </div>
))