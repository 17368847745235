import { FC } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "@/components/Button";
import { Confetti } from "@/components/Confetti/Confetti";

import styles from './styles.module.sass';
import { DialogComponents } from "../Dialog";

type ReceivedNftModalProps = {
  isOpen: boolean;
  onAction: VoidFunction;
  onClose: VoidFunction;
  name: string;
  img: string;
}

export const ReceivedNftModal: FC<ReceivedNftModalProps> = observer((
  {
    isOpen,
    onAction,
    onClose,
    name,
    img,
  }
) => {
  return (
    <>
      <DialogComponents.Container
        overlayClassName={styles.modalOverlay}
        wrapperClassName={styles.modalContentWrapper}
        isOpen={Boolean(isOpen)}
        onAction={onAction}
        onClose={onClose}
      >
        {({ handleClose, handleAction }) => (
          <DialogComponents.Content className={styles.modalContent}>
            <DialogComponents.Closer
              onClick={handleClose}
            ></DialogComponents.Closer>
            <DialogComponents.Image className={styles.modalImg} src={img} alt="icon" style={{ width: '100%', maxWidth: '311px' }} />
            <DialogComponents.TextContainer className={styles.textContainer}>
              <DialogComponents.Title className={styles.modalTitle}>
                {`Congratulations!`}
              </DialogComponents.Title>
              <DialogComponents.Text className={styles.modalText}>
                {`You have successfully minted\na ${name} NFT!`}
              </DialogComponents.Text>
            </DialogComponents.TextContainer>
            <Button
              onClick={handleAction}
              label="Got it!"
              styleType="primary"
              block={true}
            ></Button>
          </DialogComponents.Content>
        )}
      </DialogComponents.Container>
      <Confetti styles={{ zIndex: '9999', position: 'absolute', top: "-10%" }} visible={isOpen} />
    </>
  )
});