import { action, observable, flow } from "mobx";
import type RootStore from "./RootStore";
import { LeagueRatingResponse, PlayerLeague } from "@/services/types";
import { backend } from "@/services";

export default class LeaguesLeaderboardStore {
  rootStore: RootStore;
  // @observable public accessor levelUpDialog: boolean = false;
  // @observable private accessor levels: LeagueOption[] = [];
  @observable public accessor selectedLeague: number = 0;
  @observable public accessor players: PlayerLeague[] = [];
  @observable public accessor playerPosition: number | null = null;
  @observable public accessor countOfLeagueParticipants: number = 0;
  @observable public accessor playerCoins: number = 0;
  @observable public accessor league: number = 0;
  @observable public accessor loading: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action.bound public selectLeague(leagueId: number) {
    this.selectedLeague = leagueId;
  }

  @flow.bound *getPlayersLeaderboard(leagueId: number) {
    this.loading = true;

    const {
      players,
      playerPosition,
      countOfLeagueParticipants,
      league,
      playerCoins,
    }: LeagueRatingResponse = yield backend.sendLeagues({league: leagueId});

    this.players = players;
    this.playerPosition = playerPosition || 0;
    this.countOfLeagueParticipants = countOfLeagueParticipants;
    this.league = league;
    this.playerCoins = playerCoins || 0;

    this.loading = false;
  }
}
