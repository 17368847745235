import { observer } from "mobx-react-lite";
import Toast from "./Toast";
import { toastManagerStore } from "@/store";

const ToastContainer = observer(() => {
  return (
    <>
      <div
        id="toasts"
        style={{
          display: "flex",
          pointerEvents: "none",
          height: "100%",
          flexDirection: "column",
          position: "fixed",
          right: "0px",
          top: "22px",
          gap: "8px",
        }}
      ></div>
      {toastManagerStore.toasts?.map((toast) => (
        <Toast
          key={toast.id}
          id={toast.id}
          isBlack={toast.isBlack}
          message={toast.message}
          icon={toast.icon}
          duration={toast.duration}
          onClose={toastManagerStore.removeToast}
        />
      ))}
    </>
  );
});

export default ToastContainer;
