import { action, observable } from "mobx";

const moneyBagState = observable({
  isMoneyBagOpen: false,
});

const setMoneyBag = action((state: boolean) => {
  moneyBagState.isMoneyBagOpen = state;
})

export const useMoneyBag = () => {
  const openMoneyBag = () => {
    setMoneyBag(true);
  };

  const closeMoneyBag = () => {
    setMoneyBag(false);
  };

  return {
    openMoneyBag,
    closeMoneyBag,
    moneyBagState,
  }
};
