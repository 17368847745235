import React from "react";
import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";

interface LeagueUserProps {
  img: string;
  userName: string;
  coins?: number;
  leagueName?: string;
}

export const ReferralUser: React.FC<LeagueUserProps> = ({
  img,
  userName,
  leagueName,
  coins,
}) => {
  return (
    <div className={styles.leagueUserContainer}>
      <div className={styles.leagueUser}>
        <img src={img} alt={"user"} />
        <p>{userName}</p>
      </div>
      <div className={styles.leagueContainer}>
        {leagueName && <p className={styles.leagueText}>➡️{leagueName}</p>}
        {coins && (
          <div className={styles.coinsAmount}>
            <p>{`+${coins.toLocaleString("en-US")}`} </p>
            <Coin width={16} height={16} />
          </div>
        )}
      </div>
    </div>
  );
};
