import { useState } from "react";
import { observer } from "mobx-react-lite";
import ReactModal from "react-modal";

import Coin from "@/assets/capybara-images/coin.svg?react";
import botImg from "@/assets/capybara-images/bot.png";
import ModalCloser from "@/assets/capybara-images/modalCloser.svg?react";
import boosterModalWallpaperIcon2 from "@/assets/capybara-images/boosterModalWallpaper2.png";
import { Button } from "@/components/Button";
import { Badge } from "@/components/Badge";
import { autoFeedBotStore } from "@/store";

import styles from "./autoFeedBotEarned.module.sass";

export const AutoFeedBotEarnedModal = observer(() => {
  const [closing, setClosing] = useState(false);
  const { income, viewed } = autoFeedBotStore;

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      autoFeedBotStore.setDialogViewed(true)
    }, 300);
  }

  return (
    <ReactModal
      ariaHideApp={false}
      overlayClassName={styles.modalOverlay}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleClose}
      className={`${styles.modalContainer} ${
        closing ? styles.modalClose : ""
      }`}
      isOpen={Boolean(income && !viewed)}
    >
      <div className={styles.modalContentWrapper}>
        <div
          className={styles.modalContent}
          style={{
            backgroundImage: `url(${boosterModalWallpaperIcon2})`,
          }}
        >
          <ModalCloser
            className={styles.modalCloser}
            onClick={handleClose}
          ></ModalCloser>
          <img className={styles.modalImg} src={botImg} alt="icon" />
          <Badge color="blue" size="xl">
            { `+${income}` } <Coin width={20} height={20} />
          </Badge>
          <div className={styles.textContainer}>
            <h2 className={styles.modalTitle}>
              {`Coins Earned by Auto\nFeed bot for your\nCapybara`}
            </h2>
          </div>
          <Button
            onClick={handleClose}
            label="Got it!"
            styleType="primary"
            block={true}
          ></Button>
        </div>
      </div>
    </ReactModal>
  )
});
