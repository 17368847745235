import styles from "./styles.module.css";
import { observer } from "mobx-react-lite";
import { gameStore } from "@/store";
import { formatNumber } from "@/utils";

const EnergyBar = observer(() => {
  const foodStock = gameStore.energy;
  const maxFoodStockSize = gameStore.maxEnergy;

  const progress = Math.min((foodStock / maxFoodStockSize) * 100, 100);

  return (
    <div className={styles.progressBarWrapper}>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressFoodStock}>
          {formatNumber(foodStock)}
        </div>
        <div className={styles.progressWrapper}>
          <div className={styles.progressContainer}>
            <div
              className={styles.progressBar}
              style={{ width: `${progress}%` }}
            />
            <div className={styles.progressFruit}>🍋</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EnergyBar;
