import React, { ElementType } from "react";
import styles from "./styles.module.css";

type componentsType = {
  Icon?: ElementType;
  text?: string;
  component: ElementType;
};

interface MenuProps {
  components: Array<componentsType>;
  onSelect: (index: number) => void;
  selectedComponent: number;
}

export const Menu: React.FC<MenuProps> = ({
  components,
  onSelect,
  selectedComponent,
}) => {
  return (
    <div className={styles.menuContainer}>
      <div className={styles.menu}>
        {components.map(
          ({ Icon, text }, index) =>
            Icon &&
            text && (
              <div
                onClick={() => onSelect(index)}
                style={{
                  padding: index === 3 ? "0 9px" : undefined,
                }}
                className={`${styles.menuItem} ${
                  selectedComponent === index ? styles.selectedItem : ""
                }`}
                key={text}
              >
                <Icon />
                <span>{text}</span>
              </div>
            )
        )}
      </div>
    </div>
  );
};
