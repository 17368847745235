import React, { useEffect } from "react";
import styles from "./styles.module.css";
import walletImg from "@/assets/capybara-images/walletImg.png";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Dialog } from "../Dialog";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import walletBg from "@/assets/capybara-images/walletBg.png";

interface WalletButtonProps {
  backgroundColor: string;
}

export const Wallet: React.FC<WalletButtonProps> = observer(
  ({ backgroundColor }) => {
    const navigate = useNavigate();
    const [isOpenNewWallet, setIsOpenNewWallet] = React.useState(false);

    useEffect(() => {
      if (!localStorage.getItem("completedWallet")) {
        setIsOpenNewWallet(true);
      }
    }, []);

    const handleClick = () => {
      localStorage.setItem("completedWallet", "true");
      navigate("/wallet");
    };

    return (
      <>
        <div className={styles.walletWrapper}>
          <div
            onClick={handleClick}
            style={{
              background: backgroundColor,
            }}
            className={styles.wallet}
          >
            <img src={walletImg} alt="walletImg" />
          <p>{"Wallet"}</p>
          </div>
        </div>
        <Dialog
          modalWallpaper={walletBg}
          isOpen={isOpenNewWallet}
          onClose={() => {
            setIsOpenNewWallet(false);
            localStorage.setItem("completedWallet", "true");
          }}
          onAction={() => handleClick()}
          title={"Your wallet is ready!"}
          text={"Let’s check your new wallet"}
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Check new wallet"}
        />
      </>
    );
  }
);
