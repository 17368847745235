import styles from "./styles.module.css";
import { capybaraLevels } from "@/constants";
import { observer } from "mobx-react-lite";
import useHapticFeedback from "@/hooks/useHapticFeedback";
import { boostTapStore, gameStore, leagueStore } from "@/store";
import { TouchEvent, MouseEvent } from "react";
import state from "./tapState";
import { isBackend, isProduction, isTelegram } from "@/utils";
import CoinTap from "./CoinTap";
import { createPortal } from "react-dom";

export function tap(e: TouchEvent<HTMLDivElement>) {
  for (let i = 0; i < e.changedTouches.length; i++) {
    const touch = e.changedTouches[i];
    if (boostTapStore.isActive) {
      let amount = gameStore.tap();
      if (amount > 0) {
        state.addTap(
          touch.clientX - 17 * (String(amount).length + 1) - 15,
          touch.clientY - 0,
          amount
        );
      }
      amount = gameStore.tap();
      if (amount > 0) {
        state.addTap(
          touch.clientX - 17 * (String(amount).length + 1) + 15,
          touch.clientY - 0,
          amount
        );
      }
    } else {
      const amount = gameStore.tap();
      if (amount > 0) {
        state.addTap(
          touch.clientX - 17 * (String(amount).length + 1),
          touch.clientY - 20,
          amount
        );
      }
    }
    if (
      gameStore.energy < gameStore.tapIncome &&
      gameStore.isDailyEnergyLimit
    ) {
      state.addTap(
        touch.clientX - 17 * (String("limit").length + 1),
        touch.clientY - 20,
        0
      );
    }
  }
  e.stopPropagation();
}

export function click(e: MouseEvent<HTMLDivElement>) {
  if (!isProduction && !isBackend && !isTelegram) {
    const amount = gameStore.tap();
    if (amount > 0) {
      state.addTap(
        e.clientX - 17 * (String(amount).length + 1),
        e.clientY - 20,
        amount
      );
    }
  }
}

export const Capybara = observer(() => {
  const { level } = leagueStore;
  const triggerHapticFeedback = useHapticFeedback();

  return (
    <div className={styles.capybaraContainer}>
      <img
        onTouchStart={(e) => {
          triggerHapticFeedback();
          tap(e);
        }}
        onClick={(e) => {
          triggerHapticFeedback();
          click(e);
        }}
        draggable="false"
        className={styles.capybaraImg}
        src={capybaraLevels[level].img}
        alt="state"
      />
      {createPortal(
        <div
          style={{
            position: "absolute",
            inset: 0,
            pointerEvents: "none",
            overflow: "hidden",
          }}
        >
          {state.taps.map((tap, i) => (
            <CoinTap key={i} tap={tap}></CoinTap>
          ))}
        </div>,
        document.body
      )}
    </div>
  );
});
